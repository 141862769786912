import React, { useContext, useEffect, useState } from "react";
import {  } from 'react-router-dom'

// MUI
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Divider, IconButton, Stack, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import MyContext from "../context/context";
import axios from "axios";

export default function TeamRulesUser(){
    const api = useContext(MyContext)
    
    useEffect(() => {
        getrules()
    }, [])

    const [rules, setRules] = useState([])
    const getrules = () => {
        let formData = new FormData()
        formData.append('get-rules', '')
        axios.get(`${api.api.user}?get-rules`)
        .then(function(res){
            if(res.data.status === true){
                setRules(res.data.data)
            } else {
                setRules(res.data)
            }
        })
    }

    return(
        <Box className='content' mb={{xs: 10, md: 3}} >
            <Container>
                <Box mt={2} >
                    { rules.status !== false ?
                    rules.map((row, i) =>
                    <Accordion key={i} >
                        <AccordionSummary
                        expandIcon={<ExpandMore />}
                        >
                        {row.title}
                        </AccordionSummary>
                        <AccordionDetails>
                            <img src={`../../../aircraftassets/img/${row.img}`} />
                        </AccordionDetails>
                    </Accordion> ) : null }
                </Box>
            </Container>
        </Box>
    );

}