import React from "react";
import {  } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// MUI
import { Box, Card, CardContent, Container, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

export default function AboutUser(){

    return(
        <Box className='content' mb={10} >
            <Container>
                <Box textAlign={'center'} mb={3} >
                    <Swiper
                        autoHeight={true}
                        pagination={{
                            dynamicBullets: true,
                        }}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <img src="/img/about-carousel1.png" style={{height: '200px', borderRadius: 10, objectFit: 'cover'}} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/img/about-carousel2.png" style={{height: '200px', borderRadius: 10, objectFit: 'cover'}} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/img/about-carousel3.png" style={{height: '200px', borderRadius: 10, objectFit: 'cover'}} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/img/about-carousel4.png" style={{height: '200px', borderRadius: 10, objectFit: 'cover'}} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/img/about-carousel5.png" style={{height: '200px', borderRadius: 10, objectFit: 'cover'}} />
                        </SwiperSlide>
                    </Swiper>
                </Box>

                <Card>
                    <CardContent>
                        <Typography fontSize={25} fontWeight={600} >Jet engine</Typography>
                        <Typography fontSize={14} color={'gray'} mt={1} >
                            Jet engine, any of a class of internal-combustion engines that propel aircraft by means of the rearward discharge of a jet of fluid, usually hot exhaust gases generated by burning fuel with air drawn in from the atmosphere.
                        </Typography>

                        <Typography fontSize={25} fontWeight={600} mt={3} >General characteristics</Typography>
                        <Typography fontSize={14} color={'gray'} mt={1} >
                            The prime mover of virtually all jet engines is a gas turbine. Variously called the core, gas producer, gasifier, or gas generator, the gas turbine converts the energy derived from the combustion of a liquid hydrocarbon fuel to mechanical energy in the form of a high-pressure, high-temperature airstream. This energy is then harnessed by what is termed the propulsor (e.g., airplane propeller and helicopter rotor) to generate a thrust with which to propel the aircraft.
                        </Typography>

                        <Typography fontSize={25} fontWeight={600} mt={3} >Principles of operation</Typography>
                        <Typography fontSize={25} fontWeight={600} mt={3} >The prime mover</Typography>
                        <Typography fontSize={14} color={'gray'} mt={1} >
                            The gas turbine operates on the Brayton cycle in which the working fluid is a continuous flow of air ingested into the engine’s inlet. The air is first compressed by a turbocompressor to a pressure ratio of typically 10 to 40 times the pressure of the inlet airstream (as shown in Figure 1). It then flows into a combustion chamber, where a steady stream of the hydrocarbon fuel, in the form of liquid spray droplets and vapour or both, is introduced and burned at approximately constant pressure. This gives rise to a continuous stream of high-pressure combustion products whose average temperature is typically from 980 to 1,540 °C or higher. This stream of gases flows through a turbine, which is linked by a torque shaft to the compressor and which extracts energy from the gas stream to drive the compressor. Because heat has been added to the working fluid at high pressure, the gas stream that exits from the gas generator after having been expanded through the turbine contains a considerable amount of surplus energy—i.e., gas horsepower—by virtue of its high pressure, high temperature, and high velocity, which may be harnessed for propulsion purposes.
                        </Typography>

                        <Typography fontSize={25} fontWeight={600} mt={3} >The propulsor</Typography>
                        <Typography fontSize={14} color={'gray'} mt={1} >
                            The gas horsepower generated by the prime mover in the form of hot, high-pressure gas is used to drive the propulsor, enabling it to generate thrust for propelling or lifting the aircraft. The principle on which such a thrust is produced is based on Newton’s second law of motion. This law generalizes the observation that the force (F) required to accelerate a discrete mass (m) is proportional to the product of that mass and the acceleration (a).
                        </Typography>

                        <Typography fontSize={25} fontWeight={600} mt={3} >Basic engine types</Typography>
                        <Typography fontSize={14} color={'gray'} mt={1} >
                            Achieving a high propulsive efficiency for a jet engine is dependent on designing it so that the exiting jet velocity is not greatly in excess of the flight speed. At the same time, the amount of thrust generated is proportional to that very same velocity excess that must be minimized. This set of restrictive requirements has led to the evolution of a large number of specialized variations of the basic turbojet engine, each tailored to achieve a balance of good fuel efficiency, low weight, and compact size for duty in some band of the flight speed–altitude–mission spectrum. There are two major general features characteristic of all the different engine types, however. First, in order to achieve a high propulsive efficiency, the jet velocity, or the velocity of the gas stream exiting the propulsor, is matched to the flight speed of the aircraft—slow aircraft have engines with low jet velocities and fast aircraft have engines with high jet velocities. Second, as a result of designing the jet velocity to match the flight speed, the size of the propulsor varies inversely with the flight speed of the aircraft—slow aircraft have very large propulsors, as, for example, the helicopter rotor—and the relative size of the propulsor decreases with increasing design flight speed—turboprop propellers are relatively small and turbofan fans even smaller.
                        </Typography>
                    </CardContent>
                </Card>
            </Container>
        </Box>
    );

}