import React, { useState } from "react";
import { NavLink } from 'react-router-dom'

// MUI
import { Box, Card, CardContent, Container, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { Mail, Telegram, WhatsApp } from '@mui/icons-material'

export default function SupportUser(){

    const [links] = useState([
        {name:'WhatsApp', icon: <WhatsApp />, link: "https://wa.me/919110405315"},
        {name:'Email', icon: <Mail />, link: "mailto: aircraftmfgmmc@gmail.com"},
        {name:'WhatsApp Channel', icon: <WhatsApp />, link: "https://whatsapp.com/channel/0029VaXlkGBJUM2XwuVOWc3H"},
        {name:'Telegram', icon: <Telegram />, link: "https://t.me/aircraftmfg"},
    ])

    return(
        <Box className='content' mb={{xs: 10, md: 2}} >
            <Container>
                <Card textAlign={'center'} >
                    <CardContent sx={{textAlign: 'center'}} >
                        <img src="/img/support.jpg" style={{width: 200}} />
                        <Typography fontSize={20} color={'secondary.main'} fontWeight={600} >Feel free to ask your question!</Typography>
                    </CardContent>
                </Card>
                <Card sx={{mt: 2}} >
                    <CardContent>
                        <List>
                            { links.map((row, i) =>
                            <NavLink to={row.link} >
                            <ListItem key={i} >
                                <ListItemButton>
                                    <ListItemIcon>{row.icon}</ListItemIcon>
                                    <ListItemText>{row.name}</ListItemText>
                                </ListItemButton>
                            </ListItem> <Divider/>
                            </NavLink>) }
                        </List>
                    </CardContent>
                </Card>
            </Container>
        </Box>
    );

}