import { ThemeProvider } from "@emotion/react";
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";

// MUI
import { Snackbar, createTheme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// USER
import GiftAdmin from "./admin/gift";
import MyState from "./context/state";
import CreateAccountUser from "./login/user/create";
import LoginUser from "./login/user/login";
import ResetUser from "./login/user/reset";
import AboutUser from "./user/about";
import DeviceUser from "./user/device";
import DeviceDetailsUser from "./user/device-details";
import FAQ from "./user/faq";
import HomeUser from "./user/index";
import LightApp from "./user/light-app";
import UserProductsUser from "./user/my-products";
import NewsdetailUser from "./user/news-detail";
import ProfileUser from "./user/profile";
import RechargeUser from "./user/recharge";
import Redirect from "./user/redirect";
import ContractUser from "./user/salary/contract";
import SalaryUser from "./user/salary/salary";
import SidebarUser from "./user/sidebar";
import SupportUser from "./user/support";
import TeamUser from "./user/team";
import TeamRulesUser from "./user/team-rules";
import TermConditions from "./user/term-condition";
import VerifyPaymentUser from "./user/verify-payment";
import WalletHistoryUser from "./user/wallet-history";
import WithdrawUser from "./user/withdraw";

// Admin
import HomeAdmin from "./admin";
import DeviceAdmin from "./admin/device";
import AddDevice from "./admin/device-details";
import NewsdetailAdmin from "./admin/news-detail";
import NotificationAdmin from "./admin/notification";
import RulesAdmin from "./admin/rules";
import SidebarAdmin from "./admin/sidebar";
import TransactionsAdmin from "./admin/transactions";
import UserAdmin from "./admin/user";
import PurchaseAdmin from "./admin/user/purchase";
import RechargeAdmin from "./admin/user/recharge";
import SalaryAdmin from "./admin/user/salary";
import TeamUserAdmin from "./admin/user/team";
import WalletHistoryAdmin from "./admin/user/wallet-history";
import WithdrawRequestAdmin from "./admin/withdraw-request";
import LoginAdmin from "./login/admin/login";
import ResetAdmin from "./login/admin/reset";

function App() {
	const theme = createTheme({
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1300,
				xl: 1920,
			},
		},
		palette: {
			primary: {
				light: "#6DB6FF",
				main: "#007FFF",
				dark: "#0052A4",
				contrastText: "#FFFFFF",
			},
			secondary: {
				light: "#FD912D",
				main: "#ED7603",
				dark: "#884201",
				contrastText: "#000000",
			},
			error: {
				main: "#FF0800",
			},
		},
		typography: {
			button: {
				textTransform: "initial",
			},
		},

		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						boxShadow: "none",
					},
				},
			},
		},
	});

	const fullscreen = useMediaQuery(useTheme().breakpoints.down("md"));

	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const handlesnackbar = (data) => {
		setMessage(data);
		setOpen(true);
	};

	return (
		<MyState>
			<ThemeProvider theme={theme}>
				<Snackbar open={open} message={message} onClose={() => setOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }} />
				<Routes>
					<Route path="/" element={<Redirect />} />
					{/* Login */}
					<Route path="/login-user" element={<LoginUser snackbar={handlesnackbar} />} />
					<Route path="/reset-user" element={<ResetUser snackbar={handlesnackbar} />} />
					<Route path="/create-user" element={<CreateAccountUser snackbar={handlesnackbar} />} />

					{/* Login admin */}
					<Route path="/login-admin" element={<LoginAdmin snackbar={handlesnackbar} />} />
					<Route path="/reset-admin" element={<ResetAdmin snackbar={handlesnackbar} />} />

					{/* Admin */}
					<Route path="/admin" element={<SidebarAdmin snackbar={handlesnackbar} />}>
						<Route path="home" element={<HomeAdmin snackbar={handlesnackbar} />} />
						<Route path="device" element={<DeviceAdmin snackbar={handlesnackbar} />} />
						<Route path="add-device" element={<AddDevice snackbar={handlesnackbar} />} />
						<Route path="user" element={<UserAdmin snackbar={handlesnackbar} />} />
						<Route path="read-news" element={<NewsdetailAdmin />} />
						<Route path="withdraw-request" element={<WithdrawRequestAdmin snackbar={handlesnackbar} />} />
						<Route path="gift" element={<GiftAdmin snackbar={handlesnackbar} />} />
						<Route path="wallet-history" element={<WalletHistoryAdmin />} />
						<Route path="notification" element={<NotificationAdmin snackbar={handlesnackbar} />} />
						<Route path="salary" element={<SalaryAdmin snackbar={handlesnackbar} />} />
						<Route path="rules" element={<RulesAdmin snackbar={handlesnackbar} />} />
						<Route path="purchase" element={<PurchaseAdmin snackbar={handlesnackbar} />} />
						<Route path="recharge" element={<RechargeAdmin />} />
						<Route path="user-team" element={<TeamUserAdmin />} />
						<Route path="transactions" element={<TransactionsAdmin snackbar={handlesnackbar} />} />
					</Route>

					{/* User */}
					<Route path="/user" element={<SidebarUser />}>
						<Route path="home" element={<HomeUser />} />
						<Route path="device" element={<DeviceUser />} />
						<Route path="profile" element={<ProfileUser fullscreen={fullscreen} snackbar={handlesnackbar} />} />
						<Route path="about" element={<AboutUser />} />
						<Route path="read-news" element={<NewsdetailUser />} />
						<Route path="device-detail" element={<DeviceDetailsUser snackbar={handlesnackbar} />} />
						<Route path="my-products" element={<UserProductsUser snackbar={handlesnackbar} />} />
						<Route path="wallet-history" element={<WalletHistoryUser />} />
						<Route path="support" element={<SupportUser />} />
						<Route path="withdraw" element={<WithdrawUser snackbar={handlesnackbar} />} />
						<Route path="recharge" element={<RechargeUser snackbar={handlesnackbar} />} />
						<Route path="team" element={<TeamUser />} />
						<Route path="faq" element={<FAQ />} />
						<Route path="team-rules" element={<TeamRulesUser />} />
						<Route path="light-app" element={<LightApp />} />
						<Route path="salary" element={<SalaryUser snackbar={handlesnackbar} />} />
						<Route path="contract" element={<ContractUser snackbar={handlesnackbar} />} />
					</Route>

					<Route path="verify-payment" element={<VerifyPaymentUser />} />
					<Route path="term_condition" element={<TermConditions />} />
				</Routes>
			</ThemeProvider>
		</MyState>
	);
}
export default App;
