import React, { useEffect } from "react";
import {  } from 'react-router-dom'

// MUI
import { Box, Grid, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

export default function TermConditions(){

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return(
        <Box sx={{backgroundImage: "url('/img/background.png')", backgroundPosition: 'center', backgroundSize: '100%', backgroundAttachment: 'fixed' }} >
            <Grid container>
                <Grid item xs={12} md={8} mx={'auto'} >
                    <Box padding={2} >
                        <Typography fontWeight={600} fontSize={16} >Terms and Conditions for Aircraft Manufacturing Investment Program</Typography>

                        <Typography mt={2} >Effective Date: 4 October 2024</Typography>

                        <Typography mt={2} >
                            These Terms and Conditions govern the relationship between Aircraft-MFG (hereafter referred to as “the Company”) and individuals or entities (hereafter referred to as “the Investor” or “Investors”) who invest in the Company’s aircraft manufacturing devices, projects, or programs.
                        </Typography>

                        <Typography mt={2} >
                            By participating in the investment program, you agree to the following terms and conditions:
                        </Typography>

                        <Typography mt={2} fontWeight={'bold'} mb={1} >
                            1. Investment Overview
                        </Typography>
                        <Typography>
                            1.1. The Investor agrees to invest in the specified aircraft manufacturing device or project offered by the Company.<br/>
                            1.2. The investment will be used for the design, development, and manufacturing of aircraft-related equipment and devices.<br/>
                            1.3. In return for the investment, the Investor will receive a financial return after the maturity period, which will be determined based on the Company’s success in generating revenue from the completed device or project.
                        </Typography>

                        <Typography mt={2} fontWeight={'bold'} mb={1} >2. Investment Amount</Typography>
                        <Typography>
                            2.1. The minimum investment amount is 300 per device. <br/>
                            2.2. The maximum investment amount per individual or entity is 1,00,000, unless otherwise agreed in writing by the Company.
                        </Typography>

                        <Typography mt={2} fontWeight={'bold'} mb={1} >3. Maturity Period</Typography>
                        <Typography>
                            3.1. Each investment is tied to a specified maturity period, which will be clearly communicated to the Investor at the time of investment.<br/>
                            3.2. The maturity period refers to the time from the date of investment until the date on which the Investor is eligible to receive a return on their investment.<br/>
                            3.3. Early withdrawals or redemption before the maturity date are not allowed unless otherwise stipulated in specific program terms or agreed upon by both parties.
                        </Typography>

                        <Typography mt={2} fontWeight={'bold'} mb={1} >4. Return on Investment (ROI)</Typography>
                        <Typography>
                            4.1. The expected return on investment will vary based on the performance of the aircraft manufacturing project and market conditions.<br/>
                            4.2. The Company guarantees a minimum return of 70% after the maturity period, subject to the success of the project. Any additional returns beyond this are contingent on the project’s performance.<br/>
                            4.3. Investors will be informed of the potential risks and rewards prior to making their investment.
                        </Typography>

                        <Typography mt={2} fontWeight={'bold'} mb={1} >5. Risk Disclosure</Typography>
                        <Typography>
                            5.1. The Investor acknowledges that investments in aircraft manufacturing involve significant risks, including, but not limited to, project delays, market fluctuations, regulatory changes, or technological failures.<br/>
                            5.2. The Company makes no guarantees regarding the overall profitability of the investment. The Investor agrees to invest with full knowledge of the risks involved.
                        </Typography>

                        <Typography mt={2} fontWeight={'bold'} mb={1} >6. Ownership and Control</Typography>
                        <Typography>
                            6.1. Investing in a device does not confer ownership or control over the project or the manufacturing company.<br/>
                            6.2. The Company retains full authority to manage and make decisions regarding the project, its design, production, and commercial strategies.
                        </Typography>

                        <Typography mt={2} fontWeight={'bold'} mb={1} >7. Payment of Returns</Typography>
                        <Typography>
                            7.1. Upon reaching the maturity date, the Investor will receive their return in the form of bank transfer, as per the agreed-upon terms.<br/>
                            7.2. Payments will be made within 24 business hour following the maturity date.<br/>
                            7.3. Any tax liabilities arising from the receipt of returns will be the sole responsibility of the Investor.
                        </Typography>

                        <Typography mt={2} fontWeight={'bold'} mb={1} >8. Confidentiality and Data Protection</Typography>
                        <Typography>
                            8.1. The Company will treat all personal and financial information provided by the Investor in accordance with applicable data protection laws.<br/>
                            8.2. The Investor agrees not to disclose any proprietary or confidential information shared by the Company in connection with the investment program.
                        </Typography>

                        <Typography mt={2} fontWeight={'bold'} mb={1} >9. Cancellation and Refunds</Typography>
                        <Typography>
                            9.1. The Investor may cancel their investment within 1 hour of making the investment, subject to a 30% processing fee.<br/>
                            9.2. Refunds are not available after the cancellation period has expired unless the Company terminates the project before the maturity date.
                        </Typography>

                        <Typography mt={2} fontWeight={'bold'} mb={1} >10. Termination by the Company</Typography>
                        <Typography>
                            10.1. The Company reserves the right to terminate the investment agreement if it becomes impractical or impossible to proceed with the aircraft manufacturing project due to unforeseen circumstances.<br/>
                            10.2. In such cases, the Company will refund the Investor’s principal investment minus any expenses incurred during the development phase.
                        </Typography>

                        <Typography mt={2} fontWeight={'bold'} mb={1} >11. Force Majeure</Typography>
                        <Typography>
                            11.1. The Company shall not be liable for any delays or failures in performance due to events beyond its control, including, but not limited to, acts of God, government restrictions, labor disputes, or technical failures.
                        </Typography>

                        <Typography mt={2} fontWeight={'bold'} mb={1} >12. Amendments</Typography>
                        <Typography>
                            12.1. The Company reserves the right to amend these Terms and Conditions from time to time.<br/>
                            12.2. Investors will be notified of any changes via email or the Company’s official website at least 3 days before such changes take effect.
                        </Typography>

                        <Typography fontSize={16} fontWeight={800} mt={2} >
                            By investing in this program, the Investor acknowledges that they have read, understood, and agreed to these Terms and Conditions.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );

}