import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

import MyContext from './../context/context'

// MUI
import { Box, Button, Container, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import {  } from '@mui/icons-material'
import axios from "axios";

export default function AddDevice({snackbar}){
    const api = useContext(MyContext)
    const navi = useNavigate()
    const productid = new URLSearchParams(window.location.search).get('value')

    const [action, setAction] = useState('')
    const [name, setName] = useState('')
    const [price, setPrice] = useState('')
    const [dailyincome, setDailyincome] = useState('')
    const [profit, setProfit] = useState('')
    const [period, setPeriod] = useState('')
    const [details, setDetails] = useState('')
    const [status, setStatus] = useState('active')
    const [daily, setDaily] = useState(1)

    const add_product = () => {
        setAction('uploading')
        var formData = new FormData()
        formData.append('add-product', productid)
        formData.append('name', name)
        formData.append('price', price)
        formData.append('dailyincome', dailyincome)
        formData.append('profit', profit)
        formData.append('period', period)
        formData.append('details', details)
        formData.append('daily', daily)
        formData.append('status', status)
        axios.post(api.api.admin, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg)
            if(res.data.status === true){
                navi(-1)
            }
        })
    }

    useEffect(() => {
        axios.get(`${api.api.admin}?product_details=${productid}`)
        .then(function(res){
            if(res.data.status === true){
                setName(res.data.data.name)
                setPrice(res.data.data.price)
                setDailyincome(res.data.data.daily_income)
                setProfit(res.data.data.profit)
                setPeriod(res.data.data.period)
                setDetails(res.data.data.details)
                setDaily(res.data.data.daily)
                setStatus(res.data.data.status)
            }
        })
    }, [productid])

    return(
        <Box className='content' mb={10} >
            <Container>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={1.5} >
                    <TextField fullWidth variant="outlined" type="text" label='Engine name' value={name} onChange={(event) => setName(event.target.value)} />
                    <TextField fullWidth variant="outlined" type="number" label='Price' value={price} onChange={(event) => setPrice(event.target.value)} />
                    <TextField fullWidth variant="outlined" type="number" label='Profit' value={profit} onChange={(event) => setProfit(event.target.value)} />
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={1.5} mt={1.5} >
                    <TextField fullWidth variant="outlined" type="text" label='Periods (In days)' value={period} onChange={(event) => setPeriod(event.target.value)} />
                    <TextField fullWidth variant="outlined" type="text" label='Daily income' value={dailyincome} onChange={(event) => setDailyincome(event.target.value)} />
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={1.5} mt={1.5} >
                    <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={status}
                            label="Status"
                            onChange={(event) => setStatus(event.target.value)}
                        >
                            <MenuItem value={'active'}>Active</MenuItem>
                            <MenuItem value={'close'}>Closed</MenuItem>
                            <MenuItem value={'blocked'}>Blocked</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel>Is daily</InputLabel>
                        <Select
                            value={daily}
                            label="Status"
                            onChange={(event) => setDaily(event.target.value)}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack mt={1.5} >
                    <TextField fullWidth variant="outlined" rows={4} multiline label='Device details' value={details} onChange={(event) => setDetails(event.target.value)} />
                </Stack>
                <Stack mt={2} >
                    <Button variant="contained" color="primary" size="large" onClick={() => add_product()} >Upload</Button>
                </Stack>
            </Container>
        </Box>
    );

}