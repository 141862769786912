import React, { useContext, useEffect, useState } from "react";

// MUI
import { Alert, Avatar, Box, Card, CardContent, Chip, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

import MyContext from "../../context/context";
import axios from "axios";

export default function PurchaseAdmin(){
    const api = useContext(MyContext)
    const img = useContext(MyContext)

    let url = new URLSearchParams(window.location.search).get('value')

    const [products, setProducts] = useState([])
    useEffect(() => {
        axios.get(`${api.api.admin}?user-products=${url}`)
        .then(function(res){
            if(res.data.status === false){
                setProducts(res.data)
            } else {
                setProducts(res.data.data)
            }
        })
    }, [])

    return(
        <Box className='content' pb={10}>
            <Container>
                { products.status === false ? <Alert severity="warning">{products.msg}</Alert> :
                <Grid container spacing={2} >
                    { products.map((row, i) =>
                    <Grid item xs={12} md={4} key={i} >
                        <Card>
                            <CardContent>
                                <Stack direction={'row'} justifyContent={'start'} alignItems={'center'} spacing={1.5} >
                                    <Avatar sx={{borderRadius: 1, width: 100, height: 100}} src={`${img.api.img}/${row.productid}.jpg`} />
                                    <Box>
                                        <Typography fontSize={20} fontWeight={600} >{row.name}</Typography>
                                        <Chip label={row.daily === '1' ? 'Daily income' : 'Post paid'} variant="outlined" size="small" color="primary" />
                                        <Typography mt={1} fontSize={20} fontWeight={600} >&#8377; {parseInt(row.price).toLocaleString('en-in')}</Typography>
                                    </Box>
                                </Stack>
                                <Stack mt={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'} divider={<Divider orientation="vertical" flexItem />} >
                                    <Box>
                                        <Stack direction={'row'} alignItems={'center'} spacing={1} >
                                            <Typography fontSize={12} color={'GrayText'} >Daily Income: </Typography>
                                            <Typography fontSize={16} fontWeight={600} >&#8377; {parseInt(row.daily_income).toLocaleString('en-in')}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} alignItems={'center'} spacing={1} >
                                            <Typography fontSize={12} color={'GrayText'} >Total Income: </Typography>
                                            <Typography fontSize={16} fontWeight={600} color={'primary.main'} >&#8377; {parseInt(row.profit).toLocaleString('en-in')}</Typography>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Typography fontSize={16} fontWeight={600} >{row.period} Days</Typography>
                                        <Typography fontSize={12} color={'GrayText'} >Periods of validity</Typography>
                                    </Box>
                                </Stack>
                                <Stack mb={1} mt={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography fontSize={12} color={'gray'} >Purchase date</Typography>
                                    <Chip size="small" variant="contained" color="primary" label={new Date(row.purchase_date).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', hourCycle: 'h12'})} />
                                </Stack>
                                <Divider/>
                                <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography fontSize={12} color={'gray'} >{row.daily === '1' ? 'Expire date' : 'Claim & Expire date'}</Typography>
                                    <Chip size="small" variant="contained" color="success" label={new Date(new Date(row.purchase_date).setDate(new Date(row.purchase_date).getDate() + parseInt(row.period))).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', hourCycle: 'h12'})} />
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid> ) }
                </Grid> }
            </Container>
        </Box>
    );
}