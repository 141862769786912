import React, { useEffect, useState } from "react";
import {  } from 'react-router-dom'

// MUI
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { Download } from '@mui/icons-material'

import Lottie from 'lottie-react';
import download from './../download.json';

export default function LightApp(){

    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
        event.preventDefault();
        setDeferredPrompt(event);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
        deferredPrompt.prompt();

        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
            } else {
            console.log('User dismissed the install prompt');
            }

            setDeferredPrompt(null);
        });
        }
    };

    return(
        <Box className='content' >
            <Container>
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} sx={{height: '80vh'}}>
                    <Box>
                        <Lottie animationData={download} style={{width: 300, margin: 'auto'}} />

                        <Box textAlign={'center'}>
                            <Typography color={'secondary.main'} mb={2} fontSize={25} fontWeight={500} >Download  our fastest light app</Typography>
                            <Button variant="contained" color="primary" startIcon={<Download/>} onClick={() => handleInstallClick()} >Download now</Button>
                        </Box>
                    </Box>
                </Stack>
            </Container>
        </Box>
    );

}