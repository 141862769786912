import React, { useContext, useEffect, useState } from "react";
import {  } from 'react-router-dom'
import MyContext from "../context/context";

// MUI
import { Box, Card, CardContent, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import {  } from '@mui/icons-material'
import axios from "axios";

export default function NewsdetailUser(){
    const api = useContext(MyContext)

    const newsid = new URLSearchParams(window.location.search).get('newsid')

    const [news, setNews] = useState([])
    useEffect(() => {
        get_post(newsid)
    }, [newsid])
    const get_post = (newsid) => {
        axios.get(`${api.api.admin}?news-detail=${newsid}`)
        .then(function(res){
            res.data.status === false ? setNews(res.data) : setNews(res.data.data)
        })
    }

    return(
        <Box className='content' mb={{xs: 10, md: 0}} >
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Box>
                            <video width='100%' height='auto' style={{borderRadius: 10, marginBottom: 10}} autoPlay="true" controls >
                                <source style={{width: '100%'}} src="/video/info.mp4" type="video/mp4" />
                            </video>
                        </Box>
                        <Box mt={3} >
                            <Card sx={{mt: 1}} >
                                <CardContent>
                                    <Typography fontSize={22} fontWeight={600} color={'warning.main'} >{news.heading}</Typography>

                                    <Typography mb={1} fontSize={15} color={'gray'} mt={1} >
                                        {news.description}
                                    </Typography>
                                    <Divider/>
                                    <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography fontSize={14} color={'primary.main'} fontWeight={500} >Post date - {new Date(news.post_date).toLocaleDateString(undefined, {day: '2-digit', month: 'short', year: 'numeric'})}</Typography>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );

}