import React, { useContext, useEffect, useState } from "react";

import { Alert, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogContent, Divider, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";

import { Delete, Warning } from "@mui/icons-material";
import axios from "axios";
import MyContext from "../context/context";

export default function TransactionsAdmin({ snackbar }) {
	const api = useContext(MyContext);
	const [open, setOpen] = useState("");
	const [working, setWorking] = useState("");

	const today = new Date();
	const formattedDate = today.getFullYear() + "-" + String(today.getMonth() + 1).padStart(2, "0") + "-" + String(today.getDate()).padStart(2, "0");

	const [txndate, setTxndate] = useState(formattedDate);

	const [txn, setTxn] = useState([]);
	const [totalTxn, setTotaltxn] = useState(0);
	useEffect(() => {
		get_txn(txndate);
	}, [txndate]);

	const get_txn = (txndate) => {
		axios.get(`${api.api.admin}?transaction-by-date=${txndate}`).then(function (res) {
			setTotaltxn(res.data.totalTxn.data);
			if (res.data.txn.status === true) {
				setTxn(res.data.txn.data);
			} else {
				setTxn(res.data.txn);
			}
		});
	};

	const [txnid, setTxnid] = useState("");
	const [userid, setUserid] = useState("");

	const open_delete = (txnid, userid) => {
		setTxnid(txnid);
		setUserid(userid);
		setOpen("opendelete");
	};

	const delete_txn = () => {
		setWorking("delete");
		let formData = new FormData();
		formData.append("delete-txn", txnid);
		formData.append("userid", userid);
		axios.post(api.api.admin, formData).then(function (res) {
			get_txn(txndate);
			setWorking("");
			snackbar(res.data.msg);
			setOpen("");
		});
	};

	const txntoday = txn.status !== false ? txn.reduce((total, i) => total + Number(i.amount), 0) : 0;

	return (
		<Box className="content" pb={5}>
			<Dialog open={open === "opendelete" ? true : false} fullWidth maxWidth="xs" onClose={() => setOpen("")}>
				<DialogContent>
					<Box textAlign={"center"}>
						<Warning color="error" sx={{ width: "100px", height: "100px" }} />
						<Typography fontSize={20} fontWeight={600} color={"error.main"}>
							Do you want to delete this transaction?
						</Typography>
						<Typography color={"gray"} fontSize={12}>
							Remember, you cant undo this process.
						</Typography>

						<Box textAlign={"center"} mt={2}>
							<Button color="error" sx={{ width: "100%" }} variant="contained" disabled={working === "delete" ? true : false} onClick={() => delete_txn()}>
								{working === "delete" ? <CircularProgress color="inherit" size={24} /> : "Yes, Delete"}
							</Button>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>

			<Container>
				<Grid container spacing={1} mt={0} mb={1}>
					<Grid item xs={6} md={6}>
						<Card>
							<CardContent>
								<Box textAlign={"center"} pt={1}>
									<Typography fontSize={12} color={"gray"}>
										Total Transactions
									</Typography>
									<Typography color={"primary.main"} fontSize={25} fontWeight={600}>
										&#8377; {Number(totalTxn).toLocaleString("en-in")}
									</Typography>
								</Box>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={6} md={6}>
						<Card>
							<CardContent>
								<Box textAlign={"center"} pt={1}>
									<Typography fontSize={12} color={"gray"}>
										{formattedDate} Transactions
									</Typography>
									<Typography color={"success.main"} fontSize={25} fontWeight={600}>
										&#8377; {Number(txntoday).toLocaleString("en-in")}
									</Typography>
								</Box>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<Card sx={{ mb: 2 }}>
					<CardContent>
						<Typography fontSize={12} color={"gray"}>
							Transaction Date
						</Typography>
						<TextField fullWidth type="date" value={txndate} onChange={(event) => setTxndate(event.target.value)} />
					</CardContent>
				</Card>

				<Divider>TRANSACTIONS</Divider>

				{txn.status === false ? (
					<Alert severity="warning" sx={{ mt: 2 }}>
						{txn.msg}
					</Alert>
				) : (
					txn.map((row, i) => (
						<Card sx={{ mt: 2 }} key={i}>
							<CardContent>
								<Stack mb={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
									<Box>
										<Typography fontWeight={600} fontSize={20} color={"primary.main"}>
											{row.name}
										</Typography>
										<Typography fontSize={12} color={"gray"}>
											User ID: {row.userid}
										</Typography>
										<Typography fontSize={12} color={"gray"}>
											TXN Date: {row.date}
										</Typography>
									</Box>

									<Box textAlign={"end"}>
										<Typography fontWeight={600} fontSize={20} color={"success.main"}>
											&#8377; {Number(row.amount).toLocaleString("en-in")}
										</Typography>
										<Typography fontSize={12} color={"gray"}>
											TXN ID: {row.purchaseid}
										</Typography>
									</Box>
								</Stack>
								<Divider />
								<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
									<Typography fontSize={12} color={"gray"}>
										Delete Transaction
									</Typography>
									<IconButton color="error" onClick={() => open_delete(row.tableid, row.userid)}>
										<Delete />
									</IconButton>
								</Stack>
							</CardContent>
						</Card>
					))
				)}
			</Container>
		</Box>
	);
}
