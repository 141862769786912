import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import MyContext from "../context/context";
import BalanceUser from "./balance";

// MUI
import { Avatar, Box, Button, Card, CardContent, Chip, Container, Divider, Grid, Stack, Tab, Tabs, Typography } from '@mui/material'
import { ShoppingCartCheckout } from '@mui/icons-material'
import axios from "axios";

export default function DeviceUser(){
    const api = useContext(MyContext)
    const img = useContext(MyContext)

    const navi = useNavigate()
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    useEffect(() => {
        get_products()
    }, [])

    const [products, setProducts] = useState([])
    const get_products = () => {
        axios.post(`${api.api.user}?get_products`)
        .then(function(res){
            if(res.data.status === false){
                setProducts(res.data)
            } else {
                setProducts(res.data.data)
            }
        })
    }

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return(
        <Box className='content' mb={10} >
            <Container>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="AC304H Engine" />
                    <Tab className="shake-text" label="F414 engine testing" />
                </Tabs>
                <Grid mt={2} container spacing={2} >
                    { products.status !== false && products.map((row, i) =>
                    parseInt(row.daily) === value &&
                    <Grid item xs={12} md={4} key={i} >
                        <Card>
                            <CardContent>
                                <Stack direction={'row'} justifyContent={'start'} alignItems={'center'} spacing={1.5} >
                                    <Avatar sx={{borderRadius: 1, width: 80, height: 80}} src={`${img.api.img}/${row.productid}.jpg`} />
                                    <Box>
                                        <Typography fontSize={18} fontWeight={600} noWrap >{row.name}</Typography>
                                        <Chip label={row.daily === '1' ? 'Daily income' : 'Post paid'} variant="outlined" size="small" color="primary" />
                                        <Typography mt={1} fontSize={16} fontWeight={600} >&#8377; {parseInt(row.price).toLocaleString('en-in')}</Typography>
                                    </Box>
                                </Stack>
                                <Stack mt={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'} 
                                divider={<Divider orientation="vertical" flexItem />} >
                                    <Box>
                                        <Stack direction={'row'} alignItems={'center'} spacing={1} >
                                            <Typography fontSize={12} color={'GrayText'} >Daily Income: </Typography>
                                            <Typography fontSize={16} fontWeight={600} >&#8377; {parseInt(row.daily_income).toLocaleString('en-in')}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} alignItems={'center'} spacing={1} >
                                            <Typography fontSize={12} color={'GrayText'} >Total Income: </Typography>
                                            <Typography fontSize={16} fontWeight={600} color={'primary.main'} >&#8377; {parseInt(row.profit).toLocaleString('en-in')}</Typography>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Typography fontSize={16} fontWeight={600} >{row.period} Days</Typography>
                                        <Typography fontSize={12} color={'GrayText'} >Periods of validity</Typography>
                                    </Box>
                                </Stack>
                                <Stack direction={'row'} mt={2} spacing={0.5} >
                                    <Button variant="contained" color="primary" sx={{width: '100%'}} startIcon={<ShoppingCartCheckout />} onClick={() => navi(`/user/device-detail?productid=${row.productid}`)} >Buy now</Button>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid> ) }
                </Grid>
            </Container>
        </Box>
    );

}