import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MyContext from "../context/context";
import BalanceUser from "./balance";

// MUI
import { Cancel, CheckCircleOutline, Inventory, ShoppingCartCheckout } from "@mui/icons-material";
import { Avatar, Box, Button, Card, CardContent, Chip, CircularProgress, Container, Dialog, DialogContent, Divider, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";

import Win from "./win";

export default function UserProductsUser({ snackbar }) {
	const api = useContext(MyContext);
	const img = useContext(MyContext);
	const [action, setAction] = useState([]);
	const [open, setOpen] = useState("");
	const [win, setWin] = useState([]);

	const navi = useNavigate();
	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	useEffect(() => {
		get_products();
	}, []);

	const [products, setProducts] = useState([]);
	const get_products = () => {
		axios.post(`${api.api.user}?my-products`).then(function (res) {
			if (res.data.status === false) {
				setProducts(res.data);
			} else {
				setProducts(res.data.data);
			}
		});
	};

	const claim_profit = (purchaseid) => {
		setAction(purchaseid);
		var formData = new FormData();
		formData.append("claim-profit", purchaseid);
		axios.post(api.api.user, formData).then(function (res) {
			setAction("");
			if (res.data.status === true) {
				setWin(res.data);
				get_products();
			} else {
				snackbar(res.data.msg);
			}
		});
	};

	const [records, setRecords] = useState([]);
	const claim_records = (purchaseid, purchasedate, period) => {
		let formData = new FormData();
		formData.append("device-claim-record", purchaseid);
		formData.append("purchasedate", purchasedate);
		formData.append("period", period);
		axios.post(api.api.user, formData).then(function (res) {
			if (res.data.status === true) {
				setRecords(res.data.data);
				console.log(res.data.data);
				setOpen("record");
			}
		});
	};

	return (
		<Box className="content" mb={10}>
			<Win dialog={win.status} damt={win.amount} dmsg={win.msg} />

			<Dialog open={open === "record" ? true : false} fullWidth maxWidth="xs" onClose={() => setOpen("")}>
				<DialogContent>
					<Box textAlign={"center"} mb={2}>
						<Inventory color="warning" sx={{ fontSize: 100 }} />
						<Typography fontSize={14} color={"gray"}>
							Device claim record
						</Typography>
					</Box>
					<Divider>RECORDS</Divider>
					<Box mt={2}>
						{records.map((row, i) => (
							<Box key={i} mb={2}>
								<Stack mb={0.5} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
									<Box>
										<Typography fontSize={14} fontWeight={600} color={"primary.main"}>
											{new Date(row.date).toLocaleDateString(undefined, { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit", hour12: true })}
										</Typography>
										<Typography fontSize={10} color={"gray"}>
											Amount: &#8377; {Number(row.amount).toLocaleString("en-in")}
										</Typography>
										<Typography fontSize={10} color={"gray"}>
											{row.description}
										</Typography>
									</Box>
									<Chip label={row.status === "No claim" ? "Not claimed" : "Claimed"} icon={row.status === "No claim" ? <Cancel /> : <CheckCircleOutline />} color={row.status === "No claim" ? "warning" : "success"} size="small" />
								</Stack>
								<Divider />
							</Box>
						))}
					</Box>
				</DialogContent>
			</Dialog>

			<Container>
				<BalanceUser />
				<Stack mt={3} mb={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
					<Typography fontSize={25} fontWeight={600} color={"primary.main"}>
						Engines
					</Typography>
				</Stack>
				<Grid container spacing={2}>
					{products.status !== false &&
						products.map((row, i) => (
							<Grid item xs={12} md={4} key={i}>
								<Card>
									<CardContent>
										<Stack direction={"row"} justifyContent={"start"} alignItems={"center"} spacing={1.5}>
											<Avatar sx={{ borderRadius: 1, width: 100, height: 100 }} src={`${img.api.img}/${row.productid}.jpg`} />
											<Box>
												<Typography fontSize={20} fontWeight={600}>
													{row.name}
												</Typography>
												<Chip label={row.daily === "1" ? "Daily income" : "Post paid"} variant="outlined" size="small" color="primary" />
												<Typography mt={1} fontSize={20} fontWeight={600}>
													&#8377; {parseInt(row.price).toLocaleString("en-in")}
												</Typography>
											</Box>
										</Stack>
										<Stack mt={2} direction={"row"} justifyContent={"space-between"} alignItems={"center"} divider={<Divider orientation="vertical" flexItem />}>
											<Box>
												<Stack direction={"row"} alignItems={"center"} spacing={1}>
													<Typography fontSize={12} color={"GrayText"}>
														Daily Income:{" "}
													</Typography>
													<Typography fontSize={16} fontWeight={600}>
														&#8377; {parseInt(row.daily_income).toLocaleString("en-in")}
													</Typography>
												</Stack>
												<Stack direction={"row"} alignItems={"center"} spacing={1}>
													<Typography fontSize={12} color={"GrayText"}>
														Total Income:{" "}
													</Typography>
													<Typography fontSize={16} fontWeight={600} color={"primary.main"}>
														&#8377; {parseInt(row.profit).toLocaleString("en-in")}
													</Typography>
												</Stack>
											</Box>
											<Box>
												<Typography fontSize={16} fontWeight={600}>
													{row.period} Days
												</Typography>
												<Typography fontSize={12} color={"GrayText"}>
													Periods of validity
												</Typography>
											</Box>
										</Stack>
										<Stack mb={1} mt={2} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
											<Typography fontSize={12} color={"gray"}>
												Purchase date
											</Typography>
											<Chip size="small" variant="contained" color="primary" label={new Date(row.purchase_date).toLocaleDateString(undefined, { day: "2-digit", month: "long", year: "numeric", hour: "2-digit", minute: "2-digit", hourCycle: "h12" })} />
										</Stack>
										<Divider />
										<Stack mt={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
											<Typography fontSize={12} color={"gray"}>
												{row.daily === "1" ? "Expire date" : "Claim & Expire date"}
											</Typography>
											<Chip size="small" variant="contained" color="success" label={new Date(new Date(row.purchase_date).setDate(new Date(row.purchase_date).getDate() + parseInt(row.period))).toLocaleDateString(undefined, { day: "2-digit", month: "long", year: "numeric", hour: "2-digit", minute: "2-digit", hourCycle: "h12" })} />
										</Stack>

										<Stack direction={"row"} mt={2} spacing={0.5}>
											<Button variant="outlined" onClick={() => claim_records(row.purchaseid, row.purchase_date, row.period)}>
												Record
											</Button>
											<Button variant="contained" color="primary" sx={{ width: "100%" }} startIcon={<ShoppingCartCheckout />} disabled={row.purchaseid === action ? true : row.purchase_status === "closed" ? true : false} onClick={() => claim_profit(row.purchaseid)}>
												{row.purchaseid === action ? <CircularProgress color="inherit" size={24} /> : "Claim profit"}
											</Button>
										</Stack>
									</CardContent>
								</Card>
							</Grid>
						))}
				</Grid>
			</Container>
		</Box>
	);
}
