import React, { useContext, useEffect, useState } from "react";
import {  } from 'react-router-dom'

// MUI
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Card, CardContent, Container, Divider, Paper, Stack, Tab, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import axios from "axios";
import MyContext from "../context/context";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export default function TeamUser(){
    const api = useContext(MyContext)

    const [teamB, setTeamB] = useState([])
    const [teamC, setTeamC] = useState([])
    const [teamD, setTeamD] = useState([])
    const [user, setUser] = useState([])
    const [maxPrice, setMaxPrice] = useState(0)
    const [recharge, setRecharge] = useState([])
    
    useEffect(() => {
        axios.get(`${api.api.user}?my-team`)
        .then(function(res){
            res.data.teamB.status === true ? setTeamB(res.data.teamB.data) : setTeamB(res.data.teamB)
            res.data.teamC.status === true ? setTeamC(res.data.teamC.data) : setTeamC(res.data.teamC)
            res.data.teamD.status === true ? setTeamD(res.data.teamD.data) : setTeamD(res.data.teamD)
            res.data.user.status === true ? setUser(res.data.user.data) : setUser(0)
            res.data.max_price.status === true ? setMaxPrice(res.data.max_price.data) : setMaxPrice(0)
            setRecharge(res.data.recharge)
        })
    }, [])

    const [tableValue, setTabValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const teamBrecharge = teamB.status !== false && teamB.reduce((total, i) => total+parseInt(i.total_recharge), 0)
    const teamCrecharge = teamC.status !== false && teamC.reduce((total, i) => total+parseInt(i.total_recharge), 0)
    const teamDrecharge = teamD.status !== false && teamD.reduce((total, i) => total+parseInt(i.total_recharge), 0)

    const teamBrechargeToday = teamB.status !== false && teamB.reduce((total, i) => total+parseInt(i.recharge_today), 0)
    const teamCrechargeToday = teamC.status !== false && teamC.reduce((total, i) => total+parseInt(i.recharge_today), 0)
    const teamDrechargeToday = teamD.status !== false && teamD.reduce((total, i) => total+parseInt(i.recharge_today), 0)

    const validTeamB = teamB.status !== false ? teamB.filter(i => parseInt(i['max_device_price']) > 0 ).length : 0
    const validTeamC = teamC.status !== false ? teamC.filter(i => parseInt(i['max_device_price']) > 0 ).length : 0
    const validTeamD = teamD.status !== false ? teamD.filter(i => parseInt(i['max_device_price']) > 0 ).length : 0


    return(
        <Box className='content'>
            <Container>

                <Card>
                    <CardContent>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography color={'gray'} fontSize={12} >Team profit</Typography>
                            <Typography color={'primary.main'} fontWeight={'bold'} >{parseInt(user.commission).toLocaleString('en-in')} &#8377;</Typography>
                        </Stack>
                        <Stack mt={1} mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography color={'gray'} fontSize={12} >Valid team size</Typography>
                            <Typography color={'primary.main'} fontWeight={'bold'} >
                                {validTeamB+validTeamC+validTeamD}
                            </Typography>
                        </Stack>
                        <Stack mt={1} mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography color={'gray'} fontSize={12} >Max device price</Typography>
                            <Typography color={'primary.main'} fontWeight={'bold'} >
                                {parseInt(maxPrice).toLocaleString('en-in')} &#8377;
                            </Typography>
                        </Stack>
                        <Divider/>
                        
                        <Stack pt={2} pb={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Box sx={{width: '50%'}}>
                                <Typography fontWeight={600} fontSize={20} color={'primary.main'} >{parseInt(recharge.recharge).toLocaleString('en-in')} &#8377;</Typography>
                                <Typography fontSize={10} color={'gray'}>Total Recharge Amount</Typography>
                            </Box>
                            <Box sx={{width: '50%'}} textAlign={'end'} >
                                <Typography fontWeight={600} fontSize={20} color={'primary.main'} >{parseInt(recharge.recharge_today).toLocaleString('en-in')} &#8377;</Typography>
                                <Typography fontSize={10} color={'gray'}>Today Recharge Amount</Typography>
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>

                <Box component={Paper} mb={5} >
                    <TabContext value={tableValue}>
                        <Box sx={{ mt: 1, borderBottom: 1, borderColor: 'divider', backgroundColor: 'default.main' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label={`Level B(${teamB.status !== false ? teamB.filter(i => parseInt(i['max_device_price']) > 0 ).length : 0})`} value="1" />
                                <Tab label={`Level C(${teamC.status !== false ? teamC.filter(i => parseInt(i['max_device_price']) > 0 ).length : 0})`} value="2" />
                                <Tab label={`Level D(${teamD.status !== false ? teamD.filter(i => parseInt(i['max_device_price']) > 0 ).length : 0})`} value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{padding: 0}} >
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMore />}
                                >
                                    Invalid Team ({ teamB.status !== false ? teamB.filter(i => parseInt(i['max_device_price']) === 0).length : 0 })
                                </AccordionSummary>
                                <AccordionDetails>
                                    { teamB.status === false ? <Alert severity="warning">{teamB.msg}</Alert> :
                                    teamB.map((row, i) => parseInt(row.max_device_price) === 0 &&
                                    <Stack key={i} sx={{borderBottom: '1px solid #E5E4E2'}} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Box>
                                            <Typography fontWeight={'bold'} color={'error.main'} >{row.mobile}</Typography>
                                            <Typography fontSize={12} color={'gray'} >{new Date(row.joindate).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric'})}</Typography>
                                        </Box>
                                        <Box sx={{textAlign: 'end'}} >
                                            <Typography fontWeight={'bold'} color={'error.main'} >{(row.max_device_price).toLocaleString('en-in')} &#8377;</Typography>
                                            <Typography fontSize={12} color={'gray'} >Max device price</Typography>
                                        </Box>
                                    </Stack> )}
                                </AccordionDetails>
                            </Accordion>
                            <Box padding={2}>
                                { teamB.status === false ? <Alert severity="warning">{teamB.msg}</Alert> :
                                teamB.map((row, i) => parseInt(row.max_device_price) > 0 &&
                                <Stack mb={2} key={i} sx={{borderBottom: '1px solid #E5E4E2'}} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Box>
                                        <Typography fontWeight={'bold'} color={'primary.main'} >{row.mobile}</Typography>
                                        <Typography fontSize={12} color={'gray'} >{new Date(row.joindate).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric'})}</Typography>
                                    </Box>
                                    <Box sx={{textAlign: 'end'}} >
                                        <Typography fontWeight={'bold'} color={parseInt(row.max_device_price) > parseInt(maxPrice) ? 'error.main' : 'success.main'} >{parseInt(row.max_device_price).toLocaleString('en-in')} &#8377;</Typography>
                                        <Typography fontSize={12} color={'gray'} >Max device price</Typography>
                                    </Box>
                                </Stack> )}
                            </Box>
                        </TabPanel>

                        <TabPanel value="2" sx={{padding: 0}}>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMore />}
                                >
                                    Invalid Team ({teamC.status !== false ? teamC.filter(i => parseInt(i['max_device_price']) === 0 ).length : 0})
                                </AccordionSummary>
                                <AccordionDetails>
                                    { teamC.status === false ? <Alert severity="warning">{teamC.msg}</Alert> :
                                    teamC.map((row, i) => parseInt(row.max_device_price) === 0 &&
                                    <Stack key={i} sx={{borderBottom: '1px solid #E5E4E2'}} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Box>
                                            <Typography fontWeight={'bold'} color={'error.main'} >{row.mobile}</Typography>
                                            <Typography fontSize={12} color={'gray'} >{new Date(row.joindate).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric'})}</Typography>
                                        </Box>
                                        <Box sx={{textAlign: 'end'}} >
                                            <Typography fontWeight={'bold'} color={'error.main'} >{(row.max_device_price).toLocaleString('en-in')} &#8377;</Typography>
                                            <Typography fontSize={12} color={'gray'} >Max device price</Typography>
                                        </Box>
                                    </Stack> )}
                                </AccordionDetails>
                            </Accordion>
                            <Box padding={2}>
                                { teamC.status === false ? <Alert severity="warning">{teamC.msg}</Alert> :
                                teamC.map((row, i) => parseInt(row.max_device_price) > 0 &&
                                <Stack mb={2} key={i} sx={{borderBottom: '1px solid #E5E4E2'}} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Box>
                                        <Typography fontWeight={'bold'} color={'primary.main'} >{row.mobile}</Typography>
                                        <Typography fontSize={12} color={'gray'} >{new Date(row.joindate).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric'})}</Typography>
                                    </Box>
                                    <Box sx={{textAlign: 'end'}} >
                                        <Typography fontWeight={'bold'} color={parseInt(row.max_device_price) > parseInt(maxPrice) ? 'error.main' : 'success.main'} >{parseInt(row.max_device_price).toLocaleString('en-in')} &#8377;</Typography>
                                        <Typography fontSize={12} color={'gray'} >Max device price</Typography>
                                    </Box>
                                </Stack> )}
                            </Box>
                        </TabPanel>
                        <TabPanel value="3" sx={{padding: 0}}>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMore />}
                                >
                                    Invalid Team ({teamD.status !== false ? teamD.filter(i => parseInt(i['max_device_price']) === 0 ).length : 0})
                                </AccordionSummary>
                                <AccordionDetails>
                                    { teamD.status === false ? <Alert severity="warning">{teamD.msg}</Alert> :
                                    teamD.map((row, i) => parseInt(row.max_device_price) === 0 &&
                                    <Stack key={i} sx={{borderBottom: '1px solid #E5E4E2'}} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Box>
                                            <Typography fontWeight={'bold'} color={'error.main'} >{row.mobile}</Typography>
                                            <Typography fontSize={12} color={'gray'} >{new Date(row.joindate).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric'})}</Typography>
                                        </Box>
                                        <Box sx={{textAlign: 'end'}} >
                                            <Typography fontWeight={'bold'} color={'error.main'} >{(row.max_device_price).toLocaleString('en-in')} &#8377;</Typography>
                                            <Typography fontSize={12} color={'gray'} >Max device price</Typography>
                                        </Box>
                                    </Stack> )}
                                </AccordionDetails>
                            </Accordion>
                            <Box padding={2}>
                                { teamD.status === false ? <Alert severity="warning">{teamD.msg}</Alert> :
                                teamD.map((row, i) => parseInt(row.max_device_price) > 0 &&
                                <Stack mb={2} key={i} sx={{borderBottom: '1px solid #E5E4E2'}} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Box>
                                        <Typography fontWeight={'bold'} color={'primary.main'} >{row.mobile}</Typography>
                                        <Typography fontSize={12} color={'gray'} >{new Date(row.joindate).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric'})}</Typography>
                                    </Box>
                                    <Box sx={{textAlign: 'end'}} >
                                        <Typography fontWeight={'bold'} color={parseInt(row.max_device_price) > parseInt(maxPrice) ? 'error.main' : 'success.main'} >{parseInt(row.max_device_price).toLocaleString('en-in')} &#8377;</Typography>
                                        <Typography fontSize={12} color={'gray'} >Max device price</Typography>
                                    </Box>
                                </Stack> )}
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Container>
        </Box>
    );

}