import React, {  } from "react";
import {  } from 'react-router-dom'

// MUI
import { Accordion, AccordionDetails, AccordionSummary, Box, Container } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

export default function FAQ(){

    const faq = [
        {heading: 'In what year was Aircraft-mfg in india founded?', text: 'Aircraft-MFG was founded in the year of 2019'},
        {heading: 'When will Aircraft-mfg be operational in India?', text: 'Aircraft-MFG was operational in the market 2024'},
        {heading: 'What is an Jet engine?', text: 'Jet engine, any of a class of internal-combustion engines that propel aircraft by means of the rearward discharge of a jet of fluid, usually hot exhaust gases generated by burning fuel with air drawn in from the atmosphere.'},
        {heading: 'Can I invest in Aircraft-mfg for the long term?', text: "Any adult can invest in Aircraft-mfg at any time. As time changes, and Aircraft-mfg becomes increasingly mature, investors' returns"},
        {heading: 'Is it safe to invest in Aircraft-mfg?', text: "Absolutely safe, Aircraft-mfg already has a perfect industrial chain."},
        {heading: "⁠Where do investors' income come from?", text: "Investors' income comes from jet engine profits. Investors can receive fixed income."},
        {heading: "What is investors' money used for?", text: "Engine operating costs, such as electricity, cooling, maintenance, updates, labor, etc."},
        {heading: "What is the future of jet engine?", text: "Collaborating with France on manufacturing an engine for India’s fifth-generation fighter jet and working on its design and development is the subject of ongoing discussions between the two countries, Indian envoy to France Jawed Ashraf has said. The new engine is meant to power the Advanced Medium Combat Aircraft (AMCA), which is being developed. Discussions are in progress between Safran and the Defence Research and Development Organisation’s Aeronautical Development Agency and Gas Turbine Research Establishment on arriving at a set of specifications that complies with the country’s future fighter jet requirements, the envoy said at a press conference. The subject always features in conversations between French President Emmanuel Macron and Prime Minister Narendra Modi, he added. The decision to jointly develop the aero engine was announced during Mr. Modi’s visit to France in July 2023. \nThe deal will give India access to several technologies and industrial processes involved in the manufacture of jet engines and increase the capabilities of both public and private industries in India."},
    ]

    return(
        <Box className='content' mb={{xs: 10, md: 2}} >
            <Container>
                { faq.map((row) =>
                <Accordion key={row.heading} >
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    >
                    {row.heading}
                    </AccordionSummary>
                    <AccordionDetails>
                        {row.text}
                    </AccordionDetails>
                </Accordion> ) }
            </Container>
        </Box>
    );

}