import MyContext from "./context"

const MyState = (props) => {
    
    const api = {
        "admin" : "/aircraftassets/api/index/admin",
        "user" : "/aircraftassets/api/index/user",
        "login" : "/aircraftassets/api/index/login",
        "cookie" : "/aircraftassets/api/index/cookie",
        "img" : "/aircraftassets/img/",
    }

    return (
        <MyContext.Provider value={{api}} >
            {props.children}
        </MyContext.Provider>
    );
}
export default MyState