import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import MyContext from "../context/context";

// MUI
import { Box, Button, Card, CardActions, CardContent, CardMedia, Container, Divider, IconButton, Stack, Typography } from '@mui/material'
import { East } from '@mui/icons-material'
import axios from "axios";

export default function HomeUser(){
    const api = useContext(MyContext)
    const navi = useNavigate()
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    useEffect(() => {
        get_post()
    }, [])
    const [posts, setPosts] = useState([])
    const get_post = () => {
        axios.get(`${api.api.user}?get_news`)
        .then(function(res){
            res.data.status === false ? setPosts(res.data) : setPosts(res.data.data)
        })
    }

    return(
        <Box className='content' mb={{xs: 10, md: 0}} >
            <Container>
                <video width='100%' height='auto' style={{borderRadius: 10, marginBottom: 10}} controls >
                    <source style={{width: '100%'}} src="/video/info.mp4" type="video/mp4" />
                </video>
                <Box mt={2}>
                    <Card sx={{mt: 1}} >
                        <CardMedia sx={{height: 140}} image="/img/home_img1.jpeg" />
                        <CardContent>
                            <Typography fontSize={25} fontWeight={600} color={'secondary.main'}>Jet engine</Typography>
                            <Typography className="text3" >
                            A jet engine is a type of reaction engine, discharging a fast-moving jet of heated gas (usually air) that generates thrust by jet propulsion. While this broad definition may include rocket, water jet, and hybrid propulsion, the term jet engine typically refers to an internal combustion air-breathing jet engine such as a turbojet, turbofan, ramjet, pulse jet, or scramjet. In general, jet engines are internal combustion engines.
                            </Typography>
                        </CardContent><Divider/>
                        <CardActions>
                            <Button onClick={() => window.location.href="https://en.m.wikipedia.org/wiki/Jet_engine"} >Learn more</Button>
                        </CardActions>
                    </Card>
                    <Card sx={{mt: 1}} >
                        <CardMedia sx={{height: 140}} image="/img/home_img2.webp" />
                        <CardContent>
                            <Typography fontSize={25} fontWeight={600} color={'secondary.main'}>Internal-combustion engine</Typography>
                            <Typography className="text3" >
                            Internal-combustion engine, any of a group of devices in which the reactants of combustion (oxidizer and fuel) and the products of combustion serve as the working fluids of the engine. Such an engine gains its energy from heat released during the combustion of the nonreacted working fluids, the oxidizer-fuel mixture.
                            </Typography>
                        </CardContent><Divider/>
                        <CardActions>
                            <Button onClick={() => window.location.href="https://www.britannica.com/technology/internal-combustion-engine"} >Learn more</Button>
                        </CardActions>
                    </Card>
                    <Card sx={{mt: 1}} >
                        <CardMedia sx={{height: 140}} image="/img/home_img3.png" />
                        <CardContent>
                            <Typography fontSize={25} fontWeight={600} color={'secondary.main'}>Components of jet engines</Typography>
                            <Typography className="text3" >
                            The components above, except the shaft, are linked by a parameter common to all of them, the flow rate of gas passing through the engine which is the same for all components at the same time (as a basic statement this is an acceptable approximation which ignores the addition of fuel in the combustor and bleeding air from the compressor).
                            </Typography>
                        </CardContent><Divider/>
                        <CardActions>
                            <Button onClick={() => window.location.href="https://en.m.wikipedia.org/wiki/Components_of_jet_engines"} >Learn more</Button>
                        </CardActions>
                    </Card>
                </Box>
                <Box mt={2} >
                    { posts.status !== false && posts.map((row, i) =>
                    <Card key={i} sx={{mt: 1}} >
                        <CardContent>
                            <Typography fontSize={22} fontWeight={600}>{row.heading}</Typography>

                            <Typography className="text3" mb={1} fontSize={15} color={'gray'} mt={1} >
                                {row.description}
                            </Typography>
                            <Divider/>
                            <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={14} color={'primary.main'} fontWeight={500} >Post date - {new Date(row.post_date).toLocaleDateString(undefined, {day: '2-digit', month: 'short', year: 'numeric'})}</Typography>

                                <Box>
                                    <IconButton color="secondary" onClick={() => navi(`/user/read-news?newsid=${row.newsid}`)} >
                                        <East />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card> ) }
                </Box>
            </Container>
        </Box>
    );
}