import React, { useContext, useEffect, useState } from "react";

// MUI
import { Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogContent, Paper, Stack, TextField, Typography } from '@mui/material'
import { CallMissedOutgoing } from '@mui/icons-material'
import BalanceUser from "./balance";
import MyContext from "../context/context";
import axios from "axios";

export default function WithdrawUser({snackbar}){
    const api = useContext(MyContext)
    const [open, setOpen] = useState('')
    const [action, setAction] = useState('')

    useEffect(() => {
        withdraw_history()
    }, [])

    const [whis, setWhis] = useState([])
    const withdraw_history = () => {
        axios.get(`${api.api.user}?withdraw-history`)
        .then(function(res){
            res.data.status === true ? setWhis(res.data.data) : setWhis(res.data)
        })
    }

    const [amount, setAmount] = useState('')
    const withdraw_request = () => {
        setAction('withdrawing')
        
        if(amount >= 120){
            var formData = new FormData()
            formData.append('withdraw-request', amount)
            axios.post(api.api.user, formData)
            .then(function(res){
                setAction('')
                snackbar(res.data.msg)
                if(res.data.status === true){
                    withdraw_history(); setOpen('')
                }
            })
        } else {
            setAction('')
            snackbar('Minimum withdraw amount is 120 rupees')
        }
    }

    return(
        <Box className='content' mb={{xs: 10, md: 2}} >

            <Dialog open={open === 'withdraw' ? true : false} fullWidth maxWidth='xs' onClose={() => setOpen('')} >
                <DialogContent>
                    <Stack direction={'column'} spacing={2} >
                        <TextField fullWidth variant="filled" label='Withdraw amount' type="number" value={amount} onChange={(event) => setAmount(event.target.value)} />

                        <Button color="primary" variant="contained" onClick={() => withdraw_request()} disabled={action === 'withdrawing' ? true : false} >{action === 'withdrawing' ? <CircularProgress color="inherit" size={24} /> : 'Withdraw now'}</Button>
                    </Stack>

                    <Typography fontSize={16} fontWeight={600} color={'secondary.main'} mt={3} mb={1} >Tips</Typography>
                    
                    <Typography fontSize={12} color={'gray'} >1. Minimum withdraw is &#8377; <b>120</b></Typography>
                    <Typography fontSize={12} color={'gray'} >2. Withdraw processing time will be 12 hr to 24hr.</Typography>
                    <Typography fontSize={12} color={'gray'} >3. 18% tax will deduct when you withdraw.</Typography>
                </DialogContent>
            </Dialog>
            
            <Container>
                <BalanceUser/>

                <Card sx={{mt: 2}} >
                    <CardContent>
                        <Stack direction={'column'} textAlign={'center'}>
                            <Button variant="contained" color="warning" onClick={() => setOpen('withdraw')} startIcon={<CallMissedOutgoing />} >Withdraw</Button>
                        </Stack>
                    </CardContent>
                </Card>

                <Typography color={'secondary.main'} fontSize={18} fontWeight={600} mt={2} >Withdraw history</Typography>

                { whis.status !== false && whis.map((row, i) =>
                <Stack sx={{background: row.type === 'withdraw_request' && '#F5F5DC'}} key={i} p={2} mt={2} component={Paper} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box>
                        <Typography fontSize={14} >{row.description}</Typography>
                        <Typography fontSize={14} >{(row.tableid).concat(row.userid)}</Typography>
                        <Typography fontSize={12}>{new Date(row.date).toLocaleDateString(undefined, {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}</Typography>
                    </Box>
                    <Box textAlign={'right'} >
                        <Typography fontSize={12} fontWeight={600} color={'success.main'} >Amount: &#8377; {parseInt(row.amount).toLocaleString('en-in')}</Typography>
                        <Typography fontSize={14} fontWeight={600} color={'success.main'} >Payable(18% Tax): &#8377; {(parseInt(row.amount) - parseInt(row.amount)*18/100  ).toLocaleString('en-in')}</Typography>
                    </Box>
                </Stack> ) }
            </Container>
        </Box>
    );

}