import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// MUI
import { CallReceived, Construction, Create, DoneAll, Edit } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogContent, Divider, Stack, Typography } from "@mui/material";

import axios from "axios";
import MyContext from "../../context/context";

import Lottie from "lottie-react";
import liveAnimation from "./../../lottie/live.json";

import Win from "./../win";

export default function SalaryUser({ snackbar }) {
	let navi = useNavigate();
	const api = useContext(MyContext);
	const [open, setOpen] = useState("");
	const [working, setWorking] = useState("");
	const [win, setWin] = useState([]);

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	useEffect(() => {
		get_profile();
	}, []);

	const get_profile = () => {
		axios.get(`${api.api.user}?profile`).then(function (res) {
			if (res.data.profile.status === true) {
				setOpen(res.data.profile.data.cont_status === null ? "openlive" : "");
			}
		});
	};

	const links = [
		{ name: "Novice", contract: "Not applicable", amount: 0, icon: "/img/novice.png" },
		{ name: "Amateur", contract: "3 Months", amount: 300, icon: "/img/amateur.png" },
		{ name: "Explorer", contract: "6 Months", amount: 1000, icon: "/img/explorer.png" },
		{ name: "Elite", contract: "1 Year", amount: 2000, icon: "/img/elite.png" },
		{ name: "Master", contract: "2 Years", amount: 5000, icon: "/img/master.png" },
		{ name: "Legend", contract: "3 Years", amount: 10000, icon: "/img/legend.png" },
		{ name: "Demigod", contract: "4 Years", amount: 15000, icon: "/img/demigod.png" },
		{ name: "Assistant", contract: "5 Years", amount: 20000, icon: "/img/assistant.png" },
		{ name: "Manager", contract: "Life time", amount: 40000, icon: "/img/manager.png" },
	];

	const [salary, setSalary] = useState([]);
	useEffect(() => {
		axios.get(`${api.api.user}?get-teams`).then(function (res) {
			setSalary(res.data);
		});
	}, []);

	const salaryamount = salary.level === "Novice" ? 0 : salary.level === "Amateur" ? 300 : salary.level === "Explorer" ? 1000 : salary.level === "Elite" ? 2000 : salary.level === "Master" ? 5000 : salary.level === "Legend" ? 10000 : salary.level === "Demigod" ? 15000 : salary.level === "Assistant" ? 20000 : salary.level === "Manager" ? 40000 : 0;

	const receive_salary = () => {
		setWorking("receiving");
		const formData = new FormData();
		formData.append("receive-salary", "");
		axios.post(api.api.user, formData).then(function (res) {
			setWorking("");
			if (res.data.status === false) {
				snackbar(res.data.msg);
			} else {
				setWin(res.data);
			}
		});
	};

	return (
		<Box className="content" pb={10}>
			<Win dialog={win.status} damt={win.amount} dmsg={win.msg} />
			<Dialog open={open === "openlive" ? true : false} fullWidth maxWidth="xs" onClose={() => setOpen("")}>
				<DialogContent>
					<Box textAlign={"center"}>
						<Box>
							<Lottie animationData={liveAnimation} style={{ width: 200, margin: "auto" }} />
						</Box>

						<Box textAlign={"center"}>
							<Typography fontSize={27} fontWeight={600} color={"primary.main"}>
								Weekly salary live now!
							</Typography>
							<Typography fontSize={12} color={"gray"} mt={3} mb={0.5}>
								Remember*
							</Typography>
							<Typography mb={1} color={"gray"} fontSize={13}>
								To sign weekly salary contract prepare with Aadhaar card.
							</Typography>
							<Divider sx={{ color: "gray" }}>HINDI</Divider>
							<Typography mt={1} color={"gray"} fontSize={13}>
								साप्ताहिक वेतन अनुबंध पर हस्ताक्षर करने के लिए आधार कार्ड तैयार रखें।
							</Typography>
							<Stack direction={"column"} mt={2} spacing={1}>
								<Button variant="contained" startIcon={<Edit />} onClick={() => navi("/user/contract")}>
									Sign contract
								</Button>
								<Button onClick={() => setOpen("")}>Close</Button>
							</Stack>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
			<Dialog open={open === "construction" ? true : false} onClose={() => setOpen("")}>
				<DialogContent>
					<Box textAlign={"center"}>
						<Construction color="secondary" style={{ fontSize: 90 }} />
						<Typography fontSize={20} color={"primary.main"} fontWeight={600}>
							We are working on this project!
						</Typography>
						<Typography fontSize={12} color={"gray"} mb={2}>
							This page work in under development.
						</Typography>

						<Button variant="outlined" onClick={() => setOpen("")}>
							Ok, Thank You
						</Button>
					</Box>
				</DialogContent>
			</Dialog>
			<Container>
				<Card>
					<CardContent>
						<Box textAlign={"center"}>
							<Typography mb={1} fontSize={14} color={"gray"}>
								Weekly salary view
							</Typography>
							<Divider />
							<Typography fontSize={12} color={"gray"} mt={1}>
								Basic salary
							</Typography>
							<Typography mt={0} fontSize={40} fontWeight={800} color={"primary.main"}>
								&#8377; {parseInt(salaryamount).toLocaleString("en-in")}
							</Typography>
						</Box>
						<Box mt={2}>
							<Button variant="contained" color="primary" sx={{ width: "100%" }} startIcon={<CallReceived />} disabled={working === "receiving" ? true : false} onClick={() => receive_salary()}>
								{working === "receiving" ? <CircularProgress color="inherit" size={24} /> : "Receive salary"}
							</Button>
							<Button variant="outlined" color="info" sx={{ width: "100%", mt: 1 }} startIcon={<Create />} onClick={() => navi("/user/contract")}>
								Sign contract
							</Button>
						</Box>
					</CardContent>
				</Card>
				<Card sx={{ mt: 1 }}>
					<CardContent>
						<Typography mb={2} color={"gray"} fontSize={12}>
							Basic weekly salary standards
						</Typography>

						{links.map((row, i) => (
							<Stack borderBottom={1} borderColor={"#F5F5F5"} pt={1} pb={1} key={i} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
								<Stack direction={"row"} alignItems={"center"}>
									<img src={row.icon} style={{ width: 30 }} />
									<Box ml={2}>
										<Typography fontWeight={600} fontSize={12}>
											{row.name}
										</Typography>
										<Typography fontSize={10} color={"gray"}>
											Contract time: {row.contract}
										</Typography>
									</Box>
									{row.name === salary.level ? <DoneAll color="success" sx={{ ml: 2 }} /> : null}
								</Stack>
								<Typography fontWeight={600} color={"primary.main"}>
									&#8377; {parseInt(row.amount).toLocaleString("en-in")}
								</Typography>
							</Stack>
						))}
					</CardContent>
				</Card>

				<Card sx={{ mt: 1 }}>
					<CardContent>
						<Typography color={"primary.main"} mb={2} fontWeight={600}>
							Salary rule
						</Typography>
						<Typography fontSize={10} color={"gray"} mb={1}>
							1. Weekly salary collection time is 10am - 01pm every Sunday.
						</Typography>
						<Typography fontSize={10} color={"gray"} mb={1}>
							2. After upgrading, the salary will automatically increase and the contract period will be automatically extended.
						</Typography>
						<Typography fontSize={10} color={"gray"} mb={1}>
							3. If you forget to collect your weekly salary, the company will not responsible.
						</Typography>
						<Typography fontSize={10} color={"gray"} mb={1}>
							4. To sign this salary contract you have to pay 299 rupees from your balance.
						</Typography>
						<Typography fontSize={10} color={"gray"} mb={1}>
							5. If you violate company regulations, our company has the right to fire you.
						</Typography>
						<Typography fontSize={10} color={"gray"} mb={1}>
							6. Limited spots available, first come, first served.
						</Typography>
					</CardContent>
				</Card>
			</Container>
		</Box>
	);
}
