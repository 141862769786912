import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import MyContext from "../../context/context";
import Cookies from 'js-cookie'

// MUI
import { Avatar, Box, Button, CircularProgress, Container, CssBaseline, Stack, TextField, Typography } from '@mui/material'
import {  } from '@mui/icons-material'
import axios from "axios";

export default function ResetUser({snackbar}){
    const [action, setAction] = useState('')
    const api = useContext(MyContext)
    const navi = useNavigate()
    const auth = Cookies.get('authuser')

    useEffect(() => {
        auth === 'user'  && navi('/user/profile')
    }, [])
    
    const [mobile, setMobile] = useState('')
    const [password, setPassword] = useState('')
    const [otp, setOtp] = useState('')
    const [otpsent, setOtpsent] = useState(false)

    const send_otp = () => {
        setAction('otpsending')
        var formData = new FormData()
        formData.append('send-otp-user', mobile)
        axios.post(api.api.login, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg)
            if(res.data.status === true){
                setOtpsent(true)
            }
        })
    }

    const reset_password = () => {
        setAction('resetting')
        var formData = new FormData()
        formData.append('reset-password-user', mobile)
        formData.append('newpassword', password)
        formData.append('otp', otp)
        axios.post(api.api.login, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg)
            res.data.status === true && navi('/user/profile', {replace: true})
        })
    }

    return(
        <Box>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                    <img src="/logo.jpg" style={{width: 250}} />
                    <Typography component="h1" variant="h5" fontWeight={400} mt={3} >
                        Reset Password
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        <Stack direction={'row'} spacing={0.5}>
                            <TextField
                            margin="normal"
                            required
                            fullWidth
                            type="number"
                            label="Mobile number"
                            autoFocus
                            disabled={otpsent}
                            value={mobile}
                            onChange={(event) => setMobile(event.target.value)}
                            />
                            <Button variant="outlined" sx={{width: 130}} disabled={action === 'otpsending' ? true : otpsent} onClick={() => send_otp()} >{action === 'otpsending' ? <CircularProgress color="inherit" size={24} /> : 'Send OTP'}</Button>
                        </Stack>
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="New password"
                        type="text"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        />
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="OTP"
                        type="number"
                        autoComplete="current-password"
                        value={otp}
                        onChange={(event) => setOtp(event.target.value)}
                        />
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => reset_password()}
                        disabled={action === 'resetting' ? true : false}
                        >
                        { action === 'resetting' ? <CircularProgress color="inherit" size={24} /> :
                        'Reset' }
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Box>
    );

}