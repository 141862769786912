import React, { useContext, useEffect, useState } from "react";
import {  } from 'react-router-dom'

// MUI
import { Alert, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, Divider, Paper, Stack, TextField, Typography } from '@mui/material'
import { Assignment } from '@mui/icons-material'
import axios from "axios";
import MyContext from "../context/context";

export default function WithdrawRequestAdmin({snackbar}){
    const [open, setOpen] = useState('')
    const [action, setAction] = useState('')

    const api = useContext(MyContext)
    useEffect(() => {
        withdraw_request()
    }, [])

    const [request, setRequest] = useState([])
    const withdraw_request = () => {
        axios.get(`${api.api.admin}?withdraw-request`)
        .then(function(res){
            res.data.status === true ? setRequest(res.data.data) : setRequest(res.data)
        })
    }

    const [userid, setUserid] = useState('')
    const [tableid, setTableid] = useState('')
    const [alert, setAlert] = useState('')
    const open_request_dialog = (userid, tableid) => {
        setUserid(userid); setTableid(tableid)
        setOpen('withdraw')
    }

    const confirm_request = () => {
        setAction('withdraw')
        var formData = new FormData()
        formData.append('confirm-withdraw-request', userid)
        formData.append('tableid', tableid)
        axios.post(api.api.admin, formData)
        .then(function(res){
            snackbar(res.data.msg); setAction(''); setOpen('')
            res.data.status && withdraw_request()
        })
    }

    return(
        <Box className='content' pb={10} >
            <Dialog open={open === 'withdraw' ? true : false} fullWidth maxWidth='xs' onClose={() => setOpen('')} >
                <DialogContent>
                    <Typography fontSize={25} color={'error.main'} fontWeight={600} >Do you want to confirm this request?</Typography>
                    <Typography color={'gray'} fontSize={14} >*Remember, you can't undo this process.</Typography>
                    <Typography color={'gray'} fontSize={14} >*Did you transfered the amount to the user bank account.</Typography>

                    <Typography mb={0.5} fontSize={12} color={'gray'} mt={2} >To confirm this request please type 'confirm' below</Typography>
                    <TextField fullWidth variant="outlined" label='Type confirm' value={alert} onChange={(event) => setAlert(event.target.value)} />
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button color="inherit" onClick={() => setOpen('')} >Close</Button>
                    <Button variant="contained" color="primary" onClick={() => confirm_request()} disabled={action === 'withdraw' ? true : alert === 'confirm' ? false : true} >{action === 'withdraw' ? <CircularProgress color="inherit" size={24} /> : 'Confirm'}</Button>
                </DialogActions>
            </Dialog>
            <Container>
                <Typography fontSize={14} color={'primary.main'} fontWeight={500} mb={1} >Request for withdraw</Typography>
                { request.status === false ? <Alert severity="warning">{request.msg}</Alert> :
                request.map((row, i) =>
                <Stack onClick={() => open_request_dialog(row.userid, row.tableid)} mt={1} key={i} component={Paper} padding={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Stack direction={'row'} alignItems={'center'} >
                        <Assignment style={{fontSize: 45}} color="info" />
                        <Box ml={1} >
                            <Typography fontSize={15} fontWeight={500} color={'primary.main'} >{row.userid}</Typography>
                            <Typography fontSize={12} color={'gray'} >{new Date(row.date).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric', minute: '2-digit', hour: '2-digit'})}</Typography>
                        </Box>
                    </Stack>
                    <Typography fontSize={20} color={'secondary.main'} fontWeight={600} >&#8377; {(row.amount).toLocaleString('en-in')}</Typography>
                </Stack>) }
            </Container>
        </Box>
    );

}