import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

import MyContext from "../context/context";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// MUI
import { Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogContent, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { ShoppingCart } from '@mui/icons-material'

import axios from "axios";

export default function DeviceDetailsUser({snackbar}){
    const api = useContext(MyContext)
    const navi = useNavigate()
    const [open, setOpen] = useState('')
    const [action, setAction] = useState('')
    const productid = new URLSearchParams(window.location.search).get('productid')

    const [pro, setPro] = useState([])
    useEffect(() => {
        axios.get(`${api.api.user}?product_details=${productid}`)
        .then(function(res){
            if(res.data.status === true){
                setPro(res.data.data)
            }
        })
    }, [productid])
    
    const buy_product = () => {
        setAction('buying')
        var formData = new FormData()
        formData.append('buy-product', productid)
        axios.post(api.api.user, formData)
        .then(function(res){
            setAction('')
            if(res.data.status === false){
                snackbar(res.data.msg)
            } else {
                setOpen('bought')
            }
        })
    }
    

    return(
        <Box className='content' mb={{xs: 10, md: 0}} >
            <Dialog open={open === 'bought' ? true : false} fullWidth maxWidth='xs'>
                <DialogContent>
                    <Box textAlign={'center'}>
                        <img src="/img/gif1.gif" style={{width: '200px', height: '200px'}} />

                        <Typography mb={2} fontSize={20} fontWeight={500} color={'primary.main'} >You have successfully bought this product.</Typography>

                        <Button variant="outlined" color="secondary" onClick={() => navi('/user/my-products')} >Go to engine</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog open={open === 'buyalert' ? true : false} fullWidth maxWidth='xs' onClose={() => setOpen('')} >
                <DialogContent>
                    <Box textAlign={'center'}>
                        <Typography fontSize={25} color={'primary.main'} fontWeight={600} >Do you want to buy product?</Typography>
                        <Typography fontSize={14} color={'gray'} mb={2}>Remember, You can't undo this process</Typography>

                        <Button variant="contained" color="secondary" disabled={action === 'buying' ? true : false} onClick={() => buy_product()} >{action === 'buying' ? <CircularProgress color="inherit" size={24} /> : 'Buy now'}</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Container>
                <Swiper
                    autoHeight={true}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <img src="/img/carousel1.jpg" style={{height: '200px', borderRadius: 10, objectFit: 'cover'}} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/img/carousel2.jpg" style={{height: '200px', borderRadius: 10, objectFit: 'cover'}} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/img/carousel3.jpg" style={{height: '200px', borderRadius: 10, objectFit: 'cover'}} />
                    </SwiperSlide>
                </Swiper>

                <Grid container>
                    <Grid item xs={12} mt={3} >
                        <Card sx={{mt: 2}} >
                            <CardContent>
                                <Button variant="contained" color="primary" sx={{width: '100%'}} startIcon={<ShoppingCart />} size="large" onClick={() => setOpen('buyalert')} >Buy now</Button>
                            </CardContent>
                        </Card>
                        <Card sx={{mt: 2}} >
                            <CardContent>
                                <Typography fontSize={20} fontWeight={500} color={'primary.main'} >Engine parameter</Typography>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell align="right" >{pro.name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Price</TableCell>
                                            <TableCell align="right" >&#8377; {parseInt(pro.price).toLocaleString('en-in')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Daily income</TableCell>
                                            <TableCell align="right" >&#8377; {parseInt(pro.daily_income).toLocaleString('en-in')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Total profit</TableCell>
                                            <TableCell align="right" >&#8377; {parseInt(pro.profit).toLocaleString('en-in')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Period</TableCell>
                                            <TableCell align="right" >{pro.period} Days</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>

                        <Card sx={{mt: 2}} >
                            <CardContent>
                                <Typography fontSize={20} fontWeight={500} color={'primary.main'} >Engine details</Typography>
                                <Typography fontSize={14} color={'gray'} mt={2} >
                                    {pro.details}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );

}