import React, { useContext, useEffect, useState } from "react";

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Container, Divider, IconButton, Stack, TextField, Typography } from "@mui/material";
import { CloudUpload, Delete, ExpandMore } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import axios from "axios";

import MyContext from "../context/context";

export default function RulesAdmin({snackbar}){
    const api = useContext(MyContext)
    const [working, setWorking] = useState('')

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1
    });

    const [ruleid, setRuleid] = useState('')
    const [title, setTitle] = useState('')
    const [img, setImg] = useState(null)

    const publishrule = () => {
        if(img){
            setWorking('publishing')
            let formData = new FormData()
            formData.append('add-rule', ruleid)
            formData.append('title', title)
            formData.append('img', img)
            axios.post(api.api.admin, formData)
            .then(function(res){
                setWorking('')
                snackbar(res.data.msg)
                getrules()
                setTitle(''); setImg(null)
            })
        } else {
            snackbar('Please select a rule image')
        }
    }

    useEffect(() => {
        getrules()
    }, [])

    const [rules, setRules] = useState([])
    const getrules = () => {
        let formData = new FormData()
        formData.append('get-rules', '')
        axios.get(`${api.api.admin}?get-rules`)
        .then(function(res){
            if(res.data.status === true){
                setRules(res.data.data)
            } else {
                setRules(res.data)
            }
        })
    }

    const delete_rule = (ruleid) => {
        setWorking('delete')
        let formData = new FormData()
        formData.append('delete-rule', ruleid)
        axios.post(api.api.admin, formData)
        .then(function(res){
            setWorking('')
            snackbar(res.data.msg)
            if(res.data.status === true){
                getrules()
            }
        })
    }

    return(
        <Box className='content' pb={10} >
            <Container>
                <Box>
                    <Stack direction={{xs: 'column', md: 'row'}} spacing={0.5} >
                        <TextField size="small" fullWidth variant="outlined" placeholder="Enter title of the rule..." value={title} onChange={(event) => setTitle(event.target.value)} />
                        <Button
                            component="label"
                            role={undefined}
                            variant="outlined"
                            tabIndex={-1}
                            startIcon={<CloudUpload />}
                            >
                            Image
                            <VisuallyHiddenInput
                                type="file"
                                onChange={(event) => setImg(event.target.files[0])}
                                disabled={img !== null ? true : false}
                                accept=".jpeg, .jpg"
                            />
                        </Button>
                        <Button variant="contained" color="primary" disabled={working === 'publishing' ? true : false} onClick={() => publishrule()} >{working === 'publishing' ? <CircularProgress color="inherit" size={24} /> : 'Upload'}</Button>
                    </Stack>
                </Box>

                <Box mt={2} >
                    { rules.status !== false ?
                    rules.map((row, i) =>
                    <Accordion key={i} >
                        <AccordionSummary
                        expandIcon={<ExpandMore />}
                        >
                        {row.title}
                        </AccordionSummary>
                        <AccordionDetails>
                            <img src={`../../../aircraftassets/img/${row.img}`} />

                            <Divider/>
                            <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography color={'gray'} fontSize={12}>Delete rule</Typography>
                                <IconButton color="error" onClick={() => delete_rule(row.ruleid)} disabled={working === 'delete' ? true : false} >{working === 'delete' ? <CircularProgress color="inherit" size={24} /> : <Delete/>}</IconButton>
                            </Stack>
                        </AccordionDetails>
                    </Accordion> ) : null }
                </Box>

            </Container>
        </Box>
    );
}