import React, { useContext, useEffect, useState } from "react";
import {  } from 'react-router-dom'

// MUI
import { Alert, Box, Button, Chip, CircularProgress, Container, Paper, Stack, TextField, Typography } from '@mui/material'
import { CopyAll } from '@mui/icons-material'
import MyContext from "../context/context";
import axios from "axios";

export default function GiftAdmin({snackbar}){
    const api = useContext(MyContext)
    const [action, setAction] = useState('')

    useEffect(() => {
        gifts()
    }, [])
    const [gift, setGift] = useState([])
    const gifts = () => {
        axios.get(`${api.api.admin}?gifts`)
        .then(function(res){
            if(res.data.status === true){
                setGift(res.data.data)
            } else {
                setGift(res.data)
            }
        })
    }

    const handleCopy = (event) => {
        navigator.clipboard.writeText(event)
        .then(() => snackbar('Copied to clipboard'))
        .catch(() => snackbar('Failed to copy'));
    };

    const [amount, setAmount] = useState('')
    const [min, setMin] = useState('')
    const [max, setMax] = useState('')
    const upload_gift = () => {
        setAction('gift')
        var formData = new FormData()
        formData.append('upload-gift', amount)
        formData.append('min', min)
        formData.append('max', max)
        axios.post(api.api.admin, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg)
            res.data.status === true && gifts()
        })
    }

    return(
        <Box className='content' >
            <Container>
                <Box mb={2} >
                    <TextField variant="outlined" fullWidth label='Gift amount' type="number" value={amount} onChange={(event) => setAmount(event.target.value)} />
                    <Stack direction={'row'} mt={1} spacing={1} >
                        <TextField variant="outlined" fullWidth label='Min amount' type="number" value={min} onChange={(event) => setMin(event.target.value)} />
                        <TextField variant="outlined" fullWidth label='Max amount' type="number" value={max} onChange={(event) => setMax(event.target.value)} />
                    </Stack>
                    <Box mt={1} >
                        <Button variant="contained" sx={{width: '100%'}} onClick={() => upload_gift()} disabled={action === 'gift' ? true : false} >{action === 'gift' ? <CircularProgress color="inherit" size={24} /> : 'Upload'}</Button>
                    </Box>
                </Box>
                <Typography color={'secondary.main'} fontSize={14} fontWeight={500} mb={1} >Gift history</Typography>
                { gift.status === false ? <Alert severity="warning">{gift.msg}</Alert> :
                gift.map((row, i) =>
                <Stack mb={1} key={i} component={Paper} padding={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box>
                        <Typography fontSize={20} fontWeight={500} color={'primary.main'} >&#8377; {(row.amount).toLocaleString('en-in')}</Typography>
                        <Typography fontSize={10} color={'gray'} >Min - {row.min_amount} &#8377;, Max - {row.max_amount} &#8377;</Typography>
                    </Box>
                    <Chip clickable label={row.giftid} color="primary" icon={<CopyAll/>} onClick={() => handleCopy(row.giftid)} />
                </Stack>)}
            </Container>
        </Box>
    );

}