import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import MyContext from "../context/context";

// MUI
import { Avatar, Badge, Box, Button, Card, CardContent, Chip, Container, Divider, Grid, IconButton, Stack, Typography } from '@mui/material'
import { Add, CameraAlt, Edit } from '@mui/icons-material'
import axios from "axios";

export default function DeviceAdmin({snackbar}){
    const api = useContext(MyContext)
    const img = useContext(MyContext)

    const navi = useNavigate()
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    useEffect(() => {
        get_products()
    }, [])

    const [products, setProducts] = useState([])
    const get_products = () => {
        axios.post(`${api.api.admin}?get_products`)
        .then(function(res){
            if(res.data.status === false){
                setProducts(res.data)
            } else {
                setProducts(res.data.data)
            }
        })
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const add_photo = (event, productid) => {
        snackbar('uploading...')
        var formData = new FormData()
        formData.append('add-product-photo', productid)
        formData.append('img', event.target.files[0])
        axios.post(api.api.admin, formData)
        .then(function(res){
            snackbar('')
            snackbar(res.data.msg)
            res.data.status === true && window.location.reload()
        })
    }

    return(
        <Box className='content' mb={10} >
            <Container>
                <Stack mb={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography fontSize={25} fontWeight={600} color={'primary.main'} >Engine</Typography>
                    <Button startIcon={<Add/>} variant="outlined" color="primary" onClick={() => navi('/admin/add-device')} >Add engine</Button>
                </Stack>

                <Grid container spacing={2} >
                    { products.status !== false && products.map((row, i) =>
                    <Grid item xs={12} md={4} key={i} >
                        <Card>
                            <CardContent>
                                <Stack direction={'row'} justifyContent={'start'} alignItems={'center'} spacing={1.5} >
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        badgeContent={
                                            <IconButton component={'label'} onChange={(event) => add_photo(event, row.productid)} ><CameraAlt/><VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png" /></IconButton>
                                        }
                                        >
                                        <Avatar sx={{borderRadius: 1, width: 100, height: 100}} src={`${img.api.img}/${row.productid}.jpg`} />
                                    </Badge>
                                    <Box>
                                        <Typography fontSize={25} fontWeight={600} >{row.name}</Typography>
                                        <Chip label={row.daily === '1' ? 'Daily income' : 'Post paid'} variant="outlined" size="small" color="primary" />
                                        <Typography mt={1} fontSize={20} fontWeight={600} >&#8377; {parseInt(row.price).toLocaleString('en-in')}</Typography>
                                    </Box>
                                </Stack>
                                <Stack mt={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'} 
                                divider={<Divider orientation="vertical" flexItem />} >
                                    <Box>
                                        <Stack direction={'row'} alignItems={'center'} spacing={1} >
                                            <Typography fontSize={12} color={'GrayText'} >Daily Income: </Typography>
                                            <Typography fontSize={16} fontWeight={600} >&#8377; {parseInt(row.daily_income).toLocaleString('en-in')}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} alignItems={'center'} spacing={1} >
                                            <Typography fontSize={12} color={'GrayText'} >Total Income: </Typography>
                                            <Typography fontSize={16} fontWeight={600} color={'primary.main'} >&#8377; {parseInt(row.profit).toLocaleString('en-in')}</Typography>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Typography fontSize={16} fontWeight={600} >{row.period} Days</Typography>
                                        <Typography fontSize={12} color={'GrayText'} >Periods of validity</Typography>
                                    </Box>
                                </Stack>
                                <Stack direction={'row'} mt={2} spacing={0.5} >
                                    <Button variant="outlined" color={row.status === 'active' ? 'info' : 'warning'} sx={{textTransform: 'capitalize'}} >{row.status}</Button>
                                    <Button variant="contained" color="primary" sx={{width: '100%'}} startIcon={<Edit />} onClick={() => navi(`/admin/add-device?value=${row.productid}`)} >Edit</Button>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid> ) }
                </Grid>
            </Container>
        </Box>
    );

}