import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import MyContext from "../context/context";

// MUI
import { Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogContent, Divider, Grid, IconButton, Stack, TextField, Typography } from '@mui/material'
import { Delete, East } from '@mui/icons-material'
import axios from "axios";

export default function HomeAdmin({snackbar}){
    const api = useContext(MyContext)
    const navi = useNavigate()
    const [open, setOpen] = useState('')
    const [action, setAction] = useState('')
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const [phead, setPhead] = useState('')
    const [pdesc, setPdesc] = useState('')
    const upload_post = () => {
        setAction('uploading')
        var formData = new FormData()
        formData.append('post-news', '')
        formData.append('heading', phead)
        formData.append('description', pdesc)
        axios.post(api.api.admin, formData)
        .then(function(res){
            setAction(''); setPhead(''); setPdesc('')
            snackbar(res.data.msg)
            res.data.status === true && get_post()
        })
    }

    useEffect(() => {
        get_post()
    }, [])
    const [posts, setPosts] = useState([])
    const get_post = () => {
        axios.get(`${api.api.admin}?get_news`)
        .then(function(res){
            res.data.status === false ? setPosts(res.data) : setPosts(res.data.data)
        })
    }

    const [newsid, setNewsid] = useState('')
    const open_delete = (newsid) => {
        setNewsid(newsid)
        setOpen('delete')
    }
    const delete_post = () => {
        setAction('deleting')
        var formData = new FormData()
        formData.append('delete-news', newsid)
        axios.post(api.api.admin, formData)
        .then(function(res){
            setAction(''); setNewsid(''); setOpen('')
            snackbar(res.data.msg)
            res.data.status === true && get_post()
        })
    }

    return(
        <Box className='content' mb={{xs: 10, md: 0}} >
            <Dialog open={open === 'delete' ? true : false} fullWidth maxWidth='xs' onClose={() => setOpen('')} >
                <DialogContent>
                    <Box textAlign={'center'} >
                        <Typography color={'primary.main'} fontWeight={600} fontSize={25} mb={1} >Do you want to delete this post?</Typography>
                        <Typography fontSize={12} color={'gray'} >*This process will delete this post from everywhere</Typography>
                        <Typography fontSize={12} color={'gray'} mb={2} >*Remember, You can't undo this process</Typography>

                        <Button variant="contained" color="error" disabled={action === 'deleting' ? true : false} onClick={() => delete_post()} >{action === 'deleting' ? <CircularProgress color="inherit" size={24} /> : 'Yes, Delete'}</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Stack spacing={1.5}>
                            <TextField variant="outlined" fullWidth type="text" label='News heading' value={phead} onChange={(event) => setPhead(event.target.value)} />
                            <TextField variant="outlined" fullWidth type="text" label='Descriptions' multiline rows={4} value={pdesc} onChange={(event) => setPdesc(event.target.value)} />

                            <Box textAlign={'center'}>
                                <Button variant="contained" sx={{width: '100%'}} size="large" disabled={action === 'uploading' ? true : false} onClick={() => upload_post()} >{action === 'uploading' ? <CircularProgress color="inherit" size={24} /> : 'Post it'}</Button>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
                <Box mt={3} >
                    { posts.status !== false && posts.map((row, i) =>
                    <Card key={i} sx={{mt: 1}} >
                        <CardContent>
                            <Typography fontSize={22} fontWeight={600}>{row.heading}</Typography>

                            <Typography className="text3" mb={1} fontSize={15} color={'gray'} mt={1} >
                                {row.description}
                            </Typography>
                            <Divider/>
                            <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={14} color={'primary.main'} fontWeight={500} >Post date - {new Date(row.post_date).toLocaleDateString(undefined, {day: '2-digit', month: 'short', year: 'numeric'})}</Typography>

                                <Box>
                                    <IconButton color="error" onClick={() => open_delete(row.newsid)} >
                                        <Delete />
                                    </IconButton>

                                    <IconButton color="secondary" onClick={() => navi(`/admin/read-news?newsid=${row.newsid}`)} >
                                        <East />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card> ) }
                </Box>
            </Container>
        </Box>
    );
}