import React, { useContext, useEffect, useState } from "react";

// MUI
import { Alert, Box, Chip, Container, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import axios from "axios";

import MyContext from "../../context/context";

export default function RechargeAdmin(){
    const api = useContext(MyContext)

    let userid = new URLSearchParams(window.location.search).get('value')

    const [recharge, setRecharge] = useState([])
    useEffect(() => {
        axios.get(`${api.api.admin}?user-recharge=${userid}`)
        .then(function(res){
            if(res.data.status === false){
                setRecharge(res.data)
            } else {
                setRecharge(res.data.data)
            }
        })
    }, [])

    const totalrecharge = recharge.status !== false ? recharge.reduce((total, i) => {
        return i.type === 'recharge' ? total + Number(i.amount) : total
    }, 0) : 0

    const totalpurchase = recharge.status !== false ? recharge.reduce((total, i) => {
        return i.type === 'purchase' ? total + Number(i.amount) : total
    }, 0) : 0

    return(
        <Box className='content'>
            <Container>
                <Stack direction={'row'} spacing={1} mb={2} mt={2} >
                    <Chip label={`Recharge= ${Number(totalrecharge).toLocaleString('en-in')}`} color="success" icon={<ArrowDownward />} />
                    <Chip label={`Purchase= ${Number(totalpurchase).toLocaleString('en-in')}`} color="error" icon={<ArrowUpward />} />
                </Stack>
                { recharge.status === false ? <Alert severity="warning">{recharge.msg}</Alert> :
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">#</TableCell>
                                <TableCell align="left">TXN ID</TableCell>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Amount</TableCell>
                                <TableCell align="left">Type</TableCell>
                                <TableCell align="right">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            { recharge.map((row, i) =>
                            <TableRow key={i} >
                                <TableCell align="left">{i+1}</TableCell>
                                <TableCell align="left">{row.purchaseid}</TableCell>
                                <TableCell align="left">{new Date(row.date).toLocaleDateString(undefined, {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}</TableCell>
                                <TableCell align="left">&#8377; {Number(row.amount).toLocaleString('en-in')}</TableCell>
                                <TableCell align="left">{row.type}</TableCell>
                                <TableCell align="right">
                                    <Chip label={row.status} color={row.status === 'credit' ? 'success' : 'error'} icon={row.status === 'credit' ? <ArrowUpward/> : <ArrowDownward/>} />
                                </TableCell>
                            </TableRow> ) }
                        </TableBody>
                    </Table>
                </TableContainer> }
            </Container>
        </Box>
    );
}