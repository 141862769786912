import React, { useContext, useEffect, useState } from "react";
import {  } from 'react-router-dom'

// MUI
import { Alert, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogContent, Divider, FormControl, FormControlLabel, FormLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import MyContext from "../context/context";
import axios from "axios";
import Cookies from 'js-cookie'

export default function NotificationAdmin({snackbar}){
    const api = useContext(MyContext)
    const [action, setAction] = useState('')
    const [open, setOpen] = useState('')

    const [notificationid, setNotificationid] = useState('')
    const [desc, setDesc] = useState('')
    const [lan, setLan] = useState('english')
    const [noticolor, setNoticolor] = useState('#FF0800')
    
    const augmentnotification = () => {
        setAction('uploading')
        var formData = new FormData()
        formData.append('augment-notification', notificationid)
        formData.append('desc', desc)
        formData.append('lan', lan)
        formData.append('noticolor', noticolor)
        axios.post(api.api.admin, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg)
            if(res.data.status === true){
                getnotifications()
                setNotificationid(''); setDesc(''); setLan('english'); setNoticolor('#555D50')
            }
        })
    }
    
    useEffect(() => {
        getnotifications()
    }, [])
    const [noti, setNoti] = useState([])
    const getnotifications = () => {
        axios.get(`${api.api.admin}?get-notifications`)
        .then(function(res){
            res.data.status === true ? setNoti(res.data.data) : setNoti(res.data)
            if(res.data.status === true){
                Cookies.set('notifications', (res.data.data).length)
            } else {
                Cookies.set('notifications', 0)
            }
        })
    }

    const notification_details = (notificationid) => {
        setAction(notificationid)
        axios.get(`${api.api.admin}?notification-details=${notificationid}`)
        .then(function(res){
            window.scroll(0, 0)
            setAction('')
            if(res.data.status === true){
                setNotificationid(res.data.data.notificationid); setDesc(res.data.data.description); setLan(res.data.data.language); setNoticolor(res.data.data.noti_color)
            }
        })
    }

    const open_delete = (notificationid) => {
        setNotificationid(notificationid)
        setOpen('opendelete')
    }
    const delete_notification = () => {
        setAction('deleting')
        var formData = new FormData()
        formData.append('delete-notification', notificationid)
        axios.post(api.api.admin, formData)
        .then(function(res){
            snackbar(res.data.msg)
            setOpen('')
            setNotificationid('')
            setAction('')
            res.data.status === true && getnotifications()
        })
    }

    return(
        <Box className='content' pb={10} >
            <Dialog
                maxWidth='xs'
                open={open === 'opendelete' ? true : false}
                keepMounted
                onClose={() => setOpen('')}
            >
                <DialogContent>
                    <Box textAlign={'center'} >
                        <Typography color={'error.main'} fontWeight={600} fontSize={25} >Do you want to delete this notification?</Typography>
                        <Typography color={'gray'} fontSize={12}>*Remember, You can't undo this process</Typography>

                        <Box textAlign={'center'} mt={2} >
                            <Button color="error" variant="contained" disabled={action === 'deleting' ? true : false} onClick={() => delete_notification()} >{action === 'deleting' ? <CircularProgress color="inherit" size={24} /> : 'Yes, Delete'}</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <Container>
                <Card>
                    <CardContent>
                        <Stack spacing={1} >
                            <TextField size="small" fullWidth label='Notification description...' type="text" rows={4} multiline value={desc} onChange={(event) => setDesc(event.target.value)} />
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Notification colour</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={noticolor}
                                    onChange={(event) => setNoticolor(event.target.value)}
                                >
                                    <FormControlLabel value="#FF0800" control={<Radio style={{color: '#FF0800'}} />} label='Red' />
                                    <FormControlLabel value="#007FFF" control={<Radio style={{color: '#007FFF'}} />} label='Blue' />
                                    <FormControlLabel value="#8A2BE2" control={<Radio style={{color: '#8A2BE2'}} />} label='Violet' />
                                </RadioGroup>
                            </FormControl>
                            <Stack direction={'row'} spacing={1} >
                                <FormControl fullWidth size="small" >
                                    <InputLabel id="demo-simple-select-label" >Language</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Language"
                                        value={lan}
                                        onChange={(event) => setLan(event.target.value)}
                                    >
                                        <MenuItem value={'hindi'}>Hindi</MenuItem>
                                        <MenuItem value={'english'}>English</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button variant="contained" color="primary" sx={{width: 150}} onClick={() => augmentnotification()} disabled={action === 'uploading' ? true : false} >{action === 'uploading' ? <CircularProgress color="inherit" size={24} /> : 'Upload'}</Button>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>

                <Box mt={2} >
                    { noti.status === false ? <Alert severity="warning">{noti.msg}</Alert> :
                    noti.map((row, i) =>
                    <Card key={i} sx={{mb: 2}} >
                        <CardContent>
                            <Typography mt={1} mb={1} fontSize={18} fontWeight={500} color={row.noti_color} >{row.description}</Typography>
                            <Divider/>
                            <Stack mt={1} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography fontSize={14} fontWeight={600} color={'gray'}>Notified: {new Date(row.notified_on).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric'})}</Typography>
                                <Stack direction={'row'}>
                                    <IconButton onClick={() => open_delete(row.notificationid)} >{action === row.notificationid ? <CircularProgress color="inherit" size={24} /> :<Delete/>}</IconButton>
                                    <IconButton onClick={() => notification_details(row.notificationid)} >{action === row.notificationid ? <CircularProgress color="inherit" size={24} /> : <Edit/>}</IconButton>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card> )}
                </Box>
            </Container>
        </Box>
    );

}