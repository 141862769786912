import React, { useEffect } from "react";
import {  } from 'react-router-dom'

// MUI
import { Box } from '@mui/material'
import {  } from '@mui/icons-material'

export default function Redirect(){

    useEffect(() => {
        window.location.replace('https://aircraftmfg.in/user/profile')
    }, [])

    return(
        <Box>
            
        </Box>
    );

}