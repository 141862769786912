import React, { useEffect, useState } from "react";

import { Box, Dialog, Typography } from "@mui/material";

export default function Win({ dialog, damt, dmsg }) {
	const [open, setOpen] = useState(false);
	const [amount, setAmount] = useState(35);
	const [msg, setMsg] = useState("");

	useEffect(() => {
		setOpen(dialog);
		setAmount(damt);
		setMsg(dmsg);
	}, [dialog, damt, dmsg]);

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			fullWidth
			sx={{
				"& .MuiPaper-root": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},
			}}
		>
			<Box sx={{ position: "relative" }}>
				<img src="/img/win.png" />
				<Typography fontSize={30} sx={{ top: "40%", left: "50%" }} className="win-center" color={"white"} fontWeight={600}>
					Congratulations!
				</Typography>
				<Box sx={{ top: "49%", left: "50%", width: "250px" }} className="win-center">
					<Typography fontSize={10} color={"white"} textAlign={"center"}>
						{msg}
					</Typography>
				</Box>
				<Typography fontSize={10} sx={{ top: "68%", left: "50%" }} className="win-center" color={"GrayText"}>
					Your winning amount is
				</Typography>
				<Typography fontSize={35} sx={{ top: "75%", left: "50%" }} className="win-center" fontWeight={600} color={"primary.main"}>
					&#8377;{Number(amount).toLocaleString("en-in")}
				</Typography>
				<Box sx={{ top: "95%", left: "50%", width: "250px" }} className="win-center">
					<Typography fontSize={10} color={"white"} textAlign={"center"}>
						&#169; Aircraft - MFG | All rights reserved.
					</Typography>
				</Box>
			</Box>
		</Dialog>
	);
}
