import React, { useContext, useEffect, useState } from "react";
import {  } from 'react-router-dom'

import MyContext from "../../context/context";

// MUI
import { Box, Card, CardContent, Chip, Container, Divider, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { ArrowDownward, ArrowUpward, TrendingDown, TrendingUp } from '@mui/icons-material'
import axios from "axios";

import InfiniteScroll from 'react-infinite-scroll-component';

export default function WalletHistoryAdmin(){
    const api = useContext(MyContext)
    var userid = new URLSearchParams(window.location.search).get('userid')

    const [wallet, setWallet] = useState([])
    const [transaction, setTransaction] = useState([])
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);

    useEffect(() => {
        axios.get(`${api.api.admin}?wallet-history=${page}&userid=${userid}`)
        .then(function(res){
            if(page > 0){
                const newdata = res.data.wallet.data
                newdata.length === 0 && setHasMore(false)
                setWallet((pre) => [...pre, ...newdata])
            } else if(res.data.wallet.status === true){
                setWallet(res.data.wallet.data)
            } else {
                setWallet(res.data.wallet)
            }
            setTransaction(res.data.transaction.data)
        })
    }, [page])

    const loadmore = () => {
        setPage((page) => page + 1)
    }

    return(
        <Box className='content' mb={{xs: 10, md: 2}} >
            <Container>
                <Card>
                    <CardContent>
                        <Typography fontWeight={600} color={'primary.main'} mb={1} >Transactions</Typography>
                        <Stack mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                            <Stack direction={'row'} alignItems={'center'}>
                                <TrendingUp/>
                                <Typography fontSize={12} color={'gray'} ml={1} >Total recharge</Typography>
                            </Stack>
                            <Box>
                                <Typography fontWeight={600} color={'green'} >&#8377; {parseInt(transaction.recharge).toLocaleString('en-in')}</Typography>
                            </Box>
                        </Stack><Divider/>
                        <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                            <Stack direction={'row'} alignItems={'center'}>
                                <TrendingDown/>
                                <Typography fontSize={12} color={'gray'} ml={1} >Total withdrawal</Typography>
                            </Stack>
                            <Box>
                                <Typography fontWeight={600} color={'orange'} >&#8377; {parseInt(transaction.withdrawal).toLocaleString('en-in')}</Typography>
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>
                <TableContainer component={Paper} sx={{mt: 2}} >
                <InfiniteScroll
                    dataLength={wallet.length}
                    next={loadmore}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={<p style={{ textAlign: 'center' }}>No more data to load</p>}
                    >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" >#</TableCell>
                                <TableCell align="left">Amount</TableCell>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="right">Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {wallet.status !== false && wallet.map((row, i) => (
                            <TableRow
                            key={i}
                            >
                            <TableCell align="left" >{i+1}</TableCell>
                            <TableCell align="left">&#8377; {parseInt(row.amount).toLocaleString('en-in')}</TableCell>
                            <TableCell align="left">{row.date}</TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                            <TableCell align="right">
                                <Chip label={row.status} sx={{textTransform: 'capitalize'}} variant="contained" color={row.status === 'debit' ? 'error' : 'success'} icon={row.status === 'debit' ? <ArrowUpward /> : <ArrowDownward />} />
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </InfiniteScroll>
                </TableContainer>
            </Container>
        </Box>
    );

}