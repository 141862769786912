import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import MyContext from "../../context/context";
import Cookies from 'js-cookie'

// MUI
import { Avatar, Box, Button, CircularProgress, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material'
import {  } from '@mui/icons-material'
import axios from "axios";

export default function LoginAdmin({snackbar}){
    const [action, setAction] = useState('')
    const api = useContext(MyContext)
    const navi = useNavigate()
    const auth = Cookies.get('authuser')

    useEffect(() => {
        auth === 'admin'  && navi('/admin/home')
    }, [])

    const [mobile, setMobile] = useState('')
    const [password, setPassword] = useState('')
    const login = () => {
        setAction('logging')
        var formData = new FormData()
        formData.append('login-admin', mobile)
        formData.append('password', password)
        axios.post(api.api.login, formData)
        .then(function(res){
            snackbar(res.data.msg)
            setAction('')

            res.data.status === true && navi('/admin/home', {replace: true})
        })
    }

    return(
        <Box>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main', width: 280, height: 200 }} src="/logo.jpg" />
                    <Typography component="h1" variant="h5">
                        SIGN IN
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="mobile"
                        label="Mobile number"
                        name="Mobile number"
                        autoComplete="mobile number"
                        autoFocus
                        value={mobile}
                        onChange={(event) => setMobile(event.target.value)}
                        />
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        />
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => login()}
                        disabled={action === 'logging' ? true : false}
                        >
                            { action === 'logging' ?
                            <CircularProgress color="inherit" size={24} /> :
                            'Sign In' }
                        </Button>
                        <Grid container>
                            <Grid item xs textAlign={'right'}>
                                <NavLink to='/reset-admin'>
                                    Forgot password?
                                </NavLink>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </Box>
    );

}