import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

import MyContext from './../../context/context'

// MUI
import { Box, Button, Checkbox, CircularProgress, Container, CssBaseline, Stack, TextField, Typography } from '@mui/material'
import {  } from '@mui/icons-material'
import axios from "axios";

export default function CreateAccountUser({snackbar}){
    const api = useContext(MyContext)
    const referid = new URLSearchParams(window.location.search).get('referid')
    const [action, setAction] = useState('')
    const navi = useNavigate()
    const auth = Cookies.get('authuser')

    useEffect(() => {
        auth === 'user'  && navi('/user/profile')
    }, [])

    const [mobile, setMobile] = useState('')
    const [name, setName] = useState('')
    const [pass, setPass] = useState('')
    const [otp, setOtp] = useState('')
    const [otpsent, setOtpsent] = useState(false)
    const [isterm, setIsterm] = useState(false)

    const send_otp = () => {
        setAction('otpsending')
        var formData = new FormData()
        formData.append('send-otp-create-account', mobile)
        axios.post(api.api.login, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg)
            if(res.data.status === true){
                setOtpsent(true)
            }
        })
    }

    const create_account = () => {
        setAction('creating')
        var formData = new FormData();
        formData.append('create-user-account', mobile)
        formData.append('name', name)
        formData.append('otp', otp)
        formData.append('password', pass)
        formData.append('referby', referid)
        axios.post(api.api.login, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg)
            if(res.data.status === true){
                navi('/user/profile', {replace: true})
            }
        })
    }

    return(
        <Box>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                    <img src="/logo.jpg" style={{width: 250}} />
                    <Typography component="h1" variant="h5" mt={3} >
                        Welcome to Aircraft-MFG
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        <Stack
                        direction={'row'}
                        spacing={0.5}
                        >
                        <TextField
                        required
                        fullWidth
                        type="number"
                        label="Mobile number"
                        autoFocus
                        value={mobile}
                        onChange={(event) => setMobile(event.target.value)}
                        />
                        <Button variant="outlined" sx={{width: 130}} onClick={() => send_otp()} disabled={otpsent === true ? true : action === 'otpsending' ? true : false} >{action === 'otpsending' ? <CircularProgress color="inherit" size={24} /> : 'Send OTP'}</Button>
                        </Stack>

                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        type="text"
                        label="Name"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        />

                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        type="text"
                        value={pass}
                        onChange={(event) => setPass(event.target.value)}
                        />

                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        type="number"
                        label="OTP"
                        value={otp}
                        onChange={(event) => setOtp(event.target.value)}
                        />

                        <Stack mb={2} direction={'row'} alignItems={'center'}>
                            <Checkbox checked={isterm} onChange={() => isterm === true ? setIsterm(false) : setIsterm(true)} color="primary" />
                            <Typography fontSize={12} color={'gray'} >By continuing our app, you agree to our terms & conditions.</Typography>
                        </Stack>

                        <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        sx={{ mt: 1, mb: 2 }}
                        disabled={otpsent === false || isterm === false ? true : action === 'creating' ? true : false}
                        onClick={() => create_account()}
                        >
                        { action === 'creating' ? <CircularProgress color="inherit" size={24} /> :
                        'Sign In' }
                        </Button>

                        <Box>
                            <NavLink to={'/login-user'} >Already have an account? Login here.</NavLink>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );

}